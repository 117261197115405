import { NavLink, useLocation } from 'react-router-dom';
import './NavBar.scss';
import { isPremium, useAccountInfo } from 'hooks/account-hooks';
import { signInWithRedirect, signOut } from 'aws-amplify/auth';
import Bag from 'components/CustomIcons/Bag';
import { Button, Flex, Menu, MenuItem, Tabs } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import IconButton from 'components/shared/IconButton';
import SlideOutMenu from 'components/shared/SlideOutMenu';
import { Menu as MenuIcon, ChevronDown } from 'react-feather';
import useScreenSize from 'hooks/screen-size';

const tools: Record<string, {name: string, childRoute: boolean}> = {
  'random-tables': {name: 'Random Tables', childRoute: false},
  'hex-explorer': {name: 'Hex Explorer', childRoute: false},
  'narrate-ai': {name: 'NarrateAI', childRoute: false},
  'external-tools': {name: 'External Tools', childRoute: false},
};

export default function NavBar() {
  const [screenSize] = useScreenSize();
  const [collapsed, setCollapsed] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [accountInfo, isLoading] = useAccountInfo();

  const { pathname } = location;
  const rootPath = pathname === '/' ? pathname : pathname.split('/')[1];
  const selectedTab = tools.hasOwnProperty(rootPath) ? 'tools' : rootPath;
  const toolName = tools[rootPath]?.name ?? 'Tools';
  const isLoggedIn = accountInfo !== undefined;
  const showDonateButton = !isPremium(accountInfo);
  const routableTools = Object.keys(tools).filter(t => tools[t].childRoute === false);

  function navigateAndClose(val: string) {
    navigate(val);
    setCollapsed(true);
  }

  if (isLoading) return <></>;
  return <>
    <header className='NavBar-header'>
      <div className='NavBar-content'>
        <NavLink to='/'>
          <Bag />
        </NavLink>
        {screenSize !== 'mobile' &&
          <Flex className='NavBar-links' direction="row" gap="2rem" grow={1} marginLeft='20px' alignItems='center' justifyContent='end'>
            <Tabs.Container value={selectedTab} onValueChange={val => navigate(val)}>
              <Tabs.List justifyContent='flex-end'>
                <Tabs.Item value="/">Home</Tabs.Item>
                <Menu
                  menuAlign='end'
                  trigger={
                    <Tabs.Item className='NavBar-toolsTab' value="tools">{toolName} <ChevronDown size={18} /></Tabs.Item>
                  }
                >
                  {routableTools.map(toolUri => <>
                    <MenuItem onClick={() => navigate(`/${toolUri}`)}>{tools[toolUri].name}</MenuItem>
                  </>)}
                </Menu>
                <Tabs.Item value="about">About</Tabs.Item>
              </Tabs.List>
            </Tabs.Container>
            <div>
              {showDonateButton && <Button variation='primary' size='small' onClick={() => window.location.href = `${process.env.REACT_APP_PATREON_LINK}`}>Donate</Button>}
              {isLoggedIn ? <>
                <Button variation='link' onClick={() => signOut()}>Log out</Button>
              </> : <>
                <Button variation='link' onClick={() => signInWithRedirect()}>Log in</Button>
              </>}
            </div>
          </Flex>
        }
        <IconButton className='NavBar-toggle' onClick={() => setCollapsed(false)}><MenuIcon /></IconButton>
        <SlideOutMenu collapsed={collapsed} setCollapsed={setCollapsed} side='right'>
          <Flex className='NavBar-sidebar' direction="column" gap="2rem" grow={1} alignItems='center'>
            <Tabs.Container value={selectedTab} width='100%' onValueChange={val => navigateAndClose(val)}>
              <Tabs.List direction='column'>
                <Tabs.Item value="/">Home</Tabs.Item>
                  {routableTools.map(toolUri => <>
                    <Tabs.Item value={toolUri}>{tools[toolUri].name}</Tabs.Item>
                  </>)}
                <Tabs.Item value="about">About</Tabs.Item>
              </Tabs.List>
            </Tabs.Container>
            {isLoggedIn ? <>
              <button onClick={() => signOut()}>Log out</button>
            </> : <>
              <button onClick={() => signInWithRedirect()}>Log in</button>
            </>}
          </Flex>
        </SlideOutMenu>
      </div>
    </header>
  </>;
}
