import { useParams } from 'react-router-dom';
import './EditHex.scss';
import HexForm from 'components/HexList/HexForm';

export default function EditHex() {
  const { hexId: urlHexId } = useParams();
  const hexId = urlHexId && urlHexId !== 'new' ? urlHexId : undefined;

  return <HexForm hexId={hexId} />;
}
