import '@aws-amplify/ui-react/styles.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './App.scss';
import HexExplorer from 'components/HexExplorer';
import Home from 'components/Home';
import NarrateAI from 'components/NarrateAI';
import NavBar from 'components/NavBar';
import RandomTables from 'components/RandomTables';
import 'react-dropdown/style.css';
import { Routes, Route } from "react-router-dom"
import Modal from 'react-modal';
import EditRandomTable from 'components/RandomTables/EditRandomTable';
import { ModalStack } from 'hooks/modal-stack';
import { Amplify } from 'aws-amplify';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { CookieStorage } from 'utils/cookie-storage';
import About from 'components/About';
import HexList from 'components/HexList';
import EditHex from 'components/HexList/EditHex';
import AppFooter from 'components/AppFooter';
import PrivacyPolicy from 'components/Legal/PrivacyPolicy';
import CookiePolicy from 'components/Legal/CookiePolicy';
import LicenseInfo from 'components/Legal/LicenseInfo';
import UploadTables from 'components/RandomTables/UploadTables';
import CookieConsent from "react-cookie-consent";
import { NavLink } from 'react-router-dom';
import ExternalTools from 'components/ExternalTools';

Amplify.configure({
  Auth: {
    Cognito: {
      //  Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_USER_POOL_ID ?? '',
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID ?? '',
      // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      signUpVerificationMethod: 'code', // 'code' | 'link'
      loginWith: {
        // OPTIONAL - Hosted UI configuration
        oauth: {
          domain: 'gmtb.auth.us-east-1.amazoncognito.com',
          scopes: [
            'email',
            'openid',
          ],
          redirectSignIn: [window.location.origin],
          redirectSignOut: [window.location.origin],
          responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
        email: true
      },
    }
  }
});
cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage({
  domain: process.env.REACT_APP_AMPLIFY_COOKIE_DOMAIN, //localhost or .<domain>.com
  secure: process.env.REACT_APP_AMPLIFY_COOKIE_DOMAIN !== 'localhost', 
}));

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

Modal.setAppElement('#root');

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ModalStack>
        <div className="App">
          <NavBar />
          <div className='App-body'>
            <Routes>
              <Route path="/" element={ <Home /> } />
              <Route path="hex-explorer" element={ <HexExplorer /> } />
              <Route path="hex-explorer/hexes" element={ <HexList /> } />
              <Route path="hex-explorer/hexes/hex/:hexId" element={ <EditHex /> } />
              <Route path="random-tables" element={
                <RandomTables />
              } />
              <Route path="random-tables/table/:tableId" element={ <EditRandomTable /> } />
              <Route path="random-tables/upload" element={ <UploadTables /> } />
              <Route path="narrate-ai" element={ <NarrateAI /> } />
              <Route path="external-tools" element={ <ExternalTools /> } />
              <Route path="about" element={ <About /> } />
              <Route path="privacy-policy" element={ <PrivacyPolicy /> } />
              <Route path="cookie-policy" element={ <CookiePolicy /> } />
              <Route path="license-info" element={ <LicenseInfo /> } />
            </Routes>
            <AppFooter />
          </div>
        </div>
        <CookieConsent
          style={{
            background: '#ccc',
            color: 'rgb(13, 26, 38)'
          }}
          buttonStyle={{
            background: 'rgb(0, 126, 185)',
            color: 'white',
            borderRadius: '6px',
            padding: '6px 12px',
            fontWeight: 'bold'
          }}>
            This website uses cookies to operate and to enhance the user experience.
            See our <NavLink to='/cookie-policy'>Cookie Policy</NavLink> for more details.
          </CookieConsent>
      </ModalStack>
    </QueryClientProvider>
  );
}

export default App;
