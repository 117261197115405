import { ChangeEvent, useEffect, useState } from 'react';
import './HexForm.scss';
import { Hex, TerrainDifficulty } from 'models/hex';
import { isAdmin, useAccountInfo } from 'hooks/account-hooks';
import ToggleSwitch from 'components/shared/ToggleSwitch';
import { useNavigate } from 'react-router-dom';
import { useHex, useHexCreateMutation, useHexUpdateMutation } from 'hooks/hex-hooks';
import { useTables } from 'hooks/random-table-hooks';
import { TableCheck } from 'models/table-check';
import Select from 'react-select';
import { useModals } from 'hooks/modal-stack';
import EditTableModal from 'components/RandomTables/EditTableModal';
import IconButton from 'components/shared/IconButton';
import { Edit, SkipBack, SkipForward } from 'react-feather';

const DEFAULT_HEX_VALUES: Hex = {
  hex_id: 'new',
  name: '',
  user_id: 'new',
  terrain_difficulty: 'easy'
}

const terrainDifficultyOptions: TerrainDifficulty[] = ['easy', 'medium', 'hard'];
type TerrainOption = {value: string, label: string};
const terrainOptions: TerrainOption[] = terrainDifficultyOptions.map(terrain => ({ value: terrain, label: terrain}));
const terrainOptionMap: Record<TerrainDifficulty, TerrainOption> = terrainOptions.reduce((map, option) => ({...map, [option.value]: option}), {} as Record<string, TerrainOption>);

const availableImages = [
  'hex-canopy.png',
  'hex-desert.png',
  'hex-forest.png',
  'hex-grasslands.png',
  'hex-jungle.png',
  'hex-mountains.png',
  'hex-swamps.png',
  'hex-tundra.png',
];
const maxImgIdx = availableImages.length - 1;

export default function HexForm({hexId, onExit, onSave}: {hexId?: string, onExit?: () => void, onSave?: (hexId: number) => void}) {
  const { openModal, closeModal } = useModals();
  const [accountInfo] = useAccountInfo();
  const { data: tables, isLoading: areTablesLoading } = useTables({ userId: accountInfo?.userId });
  const navigate = useNavigate();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [hexData, setHexData] = useState<Hex>({...DEFAULT_HEX_VALUES});
  const [selectedImageIdx, setSelectedImageIdx] = useState(0);
  const { data, isLoading, isError } = useHex({hexId, defaultData: {...DEFAULT_HEX_VALUES}})

  const createMutation = useHexCreateMutation();
  const updateMutation = useHexUpdateMutation();

  useEffect(() => {
    if (data && (!hasLoaded || hexId !== undefined) ) {
      setHexData({...data});
      if (data.image_url) {
        const selectedIdx = availableImages.indexOf(data.image_url);
        if (selectedIdx > -1) setSelectedImageIdx(selectedIdx);
      }
      setHasLoaded(true);
    }
  }, [data, hasLoaded, hexId]);

  if (isLoading || !hasLoaded || areTablesLoading) return <>Loading...</>;
  if (isError) return <>Error!</>;

  type TableOption = { value: string, label: TableCheck['table_name'] };
  const tableOptionMap: Record<string, TableOption> = {};
  const tableOptions: TableOption[] = tables ? tables.results.map(table => {
    const newOption = { value: table.table_check_id, label: table.table_name};
    tableOptionMap[table.table_check_id] = newOption;
    return newOption;
  }) : [];
  const newTableOption: TableOption = { value: 'new', label: 'Add a new table...'};
  tableOptions.unshift(newTableOption);

  const handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    const typedValue = event.target.type === 'number' ? +value : value;
    syncChange(name, typedValue);
  };

  const syncChange = (name: string, value?: TerrainDifficulty | string | number | boolean | null) => {
    setHexData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  function saveHex() {
    const user_id = hexData.user_id === 'new' ? accountInfo?.userId : hexData.user_id;
    const hexToSave = { ...hexData, user_id, image_url: availableImages[selectedImageIdx] };
    const saveMethod = hexToSave.hex_id === 'new' ? createMutation.mutate : updateMutation.mutate;
    saveMethod(hexToSave, {
      onSuccess: (data) => {
        if (data.data.status === 'Error') {
          setErrorMessage(data.data.result.message);
        } else {
          onSave?.(data.data.result.hex_id);
          exitHexForm();
        }
      }
    });
  }

  function exitHexForm() {
    if (onExit) onExit();
    else navigate('/hex-explorer/hexes');
  }

  function openTableModal(id?: string) {
    openModal(EditTableModal, {tableId: id, onSave: setTableCheck, onRequestClose: () => {
      closeModal()
    }});
  }

  function setTableCheck(id?: string) {
    if (id === 'new') {
      openTableModal();
    } else {
      syncChange('table_check_id', `${id}`);
    }
  }

  function changeImgIdx(change: -1 | 1) {
    let newIdx = selectedImageIdx + change;
    if (newIdx < 0) newIdx = maxImgIdx;
    if (newIdx > maxImgIdx) newIdx = 0;
    setSelectedImageIdx(newIdx);
  }

  return <>
    <div className='HexForm-fields'>
      <label>Hex Image:</label>
      <div className='HexForm-value'>
        <IconButton onClick={() => changeImgIdx(-1)}><SkipBack /></IconButton>
        <img className='HexForm-image' src={`/${availableImages[selectedImageIdx]}`} alt={`${hexData.name}`} />
        <IconButton onClick={() => changeImgIdx(1)}><SkipForward /></IconButton>
      </div>
      <label>Hex Name:</label>
      <input className='HexForm-value' type='text' name='name' value={hexData.name} onChange={handleChange} />
      <label>Description:</label>
      <textarea
        className='HexForm-value'
        name='description'
        value={hexData.description}
        rows={10}
        onChange={handleChange}
      />
      {accountInfo && isAdmin(accountInfo) && <>
        <label>Global?:</label>
        <div className='HexForm-value'>
          <ToggleSwitch
            checked={hexData.user_id === 'global'}
            onChange={() => syncChange('user_id', hexData.user_id === 'global' ? accountInfo.userId : 'global')}
          />
        </div>
      </>}
      <label>Terrain Difficulty:</label>
      <div className='HexForm-value'>
        <Select
          defaultValue={terrainOptionMap[hexData.terrain_difficulty]}
          onChange={(opt) => syncChange('terrain_difficulty', opt?.value)}
          options={terrainOptions}
          className='HexForm-dropdown'
        />
      </div>
      <label>Table to roll:</label>
      <div className='HexForm-value'>
        <Select
          defaultValue={hexData.table_check_id ? tableOptionMap[hexData.table_check_id] : newTableOption}
          onChange={(opt) => setTableCheck(opt?.value)}
          options={tableOptions}
          className='HexForm-dropdown'
        />
        {hexData.table_check_id !== undefined && <>
          <IconButton onClick={() => {openTableModal(hexData.table_check_id)}}>
            <Edit />
          </IconButton>
        </>}
      </div>
    </div>
    <footer>
      <button onClick={exitHexForm}>Cancel</button>
      <button onClick={saveHex}>Save</button>
      {errorMessage && <>
        <p className='ErrorText'>{errorMessage}</p>
      </>}
    </footer>
  </>;
}
