import { ReactNode } from 'react';
import './GuestInfo.scss';
import { signInWithRedirect } from 'aws-amplify/auth';

export default function GuestInfo({children}: {children: ReactNode}) {
  return <>
    <div>
      {children}
      <p>Log in to get started!</p>
      <button onClick={() => signInWithRedirect()}>Log in</button>
    </div>
  </>;
}
