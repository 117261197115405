import { useEffect, useRef } from "react";

export function useClickOutside<T extends HTMLElement = HTMLDivElement>({enabled = true, onClickOutside}: {enabled?: boolean, onClickOutside: () => void}) {
  const ref = useRef<T>(null);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && enabled) {
        const {top, bottom, left, right} = ref.current.getBoundingClientRect();
        const {clientX, clientY} = event;
        if (bottom < clientY || clientY < top || right < clientX || clientX < left) {
          onClickOutside();
        }
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, enabled, onClickOutside]);

  return ref;
}