import { useHexes } from 'hooks/hex-hooks';
import './HexPicker.scss';
import { Button, Loader } from '@aws-amplify/ui-react';
import { Hex } from 'models/hex';
import { useNavigate } from 'react-router-dom';
import { useAccountInfo } from 'hooks/account-hooks';

export default function HexPicker({exploreHex}: {exploreHex: (hex: Hex) => void}) {
  const navigate = useNavigate();
  const [accountInfo] = useAccountInfo();
  const { data, error, isLoading } = useHexes({userId: accountInfo?.userId});
  const hexes = data?.results ?? [];

  if (error) return <>Error loading hexes</>;
  if (isLoading) return <><Loader /></>;
  if (hexes.length === 0) return <><p>No hexes found!</p><Button variation='primary' onClick={() => navigate(`hexes/hex/new`)}>Create one</Button></>

  return <>
    <ul className='HexPicker-list'>
      {hexes.map(hex => <>
        <li className='HexPicker-item' key={hex.hex_id}>
          <button
            className={`HexButton`}
            onClick={() => {exploreHex(hex);}}
          >
            <div className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img src={hex.image_url ?? `hex-crawler-icon.png`} alt={`${hex.name} hex`} />
                </div>
                <div className="flip-card-back">
                  <img src={`hex-crawler-icon.png`} alt='tile back' />
                </div>
              </div>
            </div>
            {hex.name}
          </button>
        </li>
      </>)}
    </ul>
  </>;
}
