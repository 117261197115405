import {useEffect, useState} from "react";

type ScreenSize = 'mobile' | 'tablet' | 'desktop';
const useScreenSize = (): [screenSize: ScreenSize, width: number] => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
            setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return [width <= 480 ? 'mobile' : width <= 1080 ? 'tablet' : 'desktop', width];
}

export default useScreenSize;