import { Heading } from '@aws-amplify/ui-react';
import './PrivacyPolicy.scss';

export default function PrivacyPolicy() {
  return <>
    <Heading level={3}>Privacy Policy for GM's Haversack</Heading>
    <p>Effective Date: 12/15/2023</p>

    <p>Welcome to GM's Haversack! We are committed to protecting your personal information and your right to privacy. This Privacy Policy outlines how we collect, use, and safeguard your information when you visit our website https://gmhaversack.com.</p>

    <ol>
      <li>
        <Heading level={4}>Information We Collect</Heading>
        <ol type='a'>
          <li>
            <Heading level={5}>Information You Provide to Us</Heading>
            <p>We collect personal information that you voluntarily provide to us when you register on the website, express an interest in obtaining information about us or our products and services, when you participate in activities on the website, or otherwise when you contact us.</p>
          </li>
          <li>
            <Heading level={5}>Information Automatically Collected</Heading>
            <p>When you visit our website, certain information is automatically collected. This may include your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our website, and other technical information.</p>
          </li>
        </ol>
      </li>
      <li>
        <Heading level={4}>How We Use Your Information</Heading>
        <p>We use the information we collect or receive:</p>
        <p>
          <ul>
            <li>To facilitate account creation and authentication process via AWS Cognito Hosted UI.</li>
            <li>To send administrative information to you, such as changes to our terms, conditions, and policies.</li>
            <li>To remember your settings and preferences, enhancing your website experience.</li>
            <li>For other business purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns, and to evaluate and improve our website, products, marketing, and your experience.</li>
          </ul>
        </p>
      </li>
      <li>
        <Heading level={4}>Cookies and Tracking Technologies</Heading>
        <p>We use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Policy.</p>
      </li>
      <li>
        <Heading level={4}>Sharing Your Information</Heading>
        <p>We may process or share your data that we hold based on the following legal basis:</p>
        <p>
          <ul>
            <li>Consent: We may process your data if you have given us specific consent to use your personal information for a specific purpose.</li>
            <li>Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.</li>
            <li>Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process.</li>
          </ul>
        </p>
      </li>
      <li>
        <Heading level={4}>Data Retention</Heading>
        <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law.</p>
      </li>
      <li>
        <Heading level={4}>Data Security</Heading>
        <p>We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process.</p>
      </li>
      <li>
        <Heading level={4}>Your Privacy Rights</Heading>
        <p>You have certain rights regarding your personal data, including the right to access, correct, update, or request deletion of your personal information.</p>
      </li>
      <li>
        <Heading level={4}>Policy Updates</Heading>
        <p>We may update this policy from time to time. The updated version will be indicated by an updated “Effective Date” and the updated version will be effective as soon as it is accessible.</p>
      </li>
      <li>
        <Heading level={4}>Contact Us</Heading>
        <p>If you have questions or comments about this policy, you may email us at gmhaversack@gmail.com.</p>
      </li>
    </ol>
  </>;
}
