import { TableCheck } from 'models/table-check';
import './ResultModal.scss';
import FixedModal from 'components/shared/FixedModal';
import { useTableRoll } from 'hooks/random-table-hooks';
import { ReactNode, useEffect, useRef } from 'react';
import ShowTableRollResults from 'components/shared/ShowTableRollResults';
import { useModals } from 'hooks/modal-stack';
import AiPromptModal from 'components/NarrateAI/AiPromptModal';
import { Loader } from '@aws-amplify/ui-react';

export default function RandomTablesResultModal({ table, onRequestClose }: { table: TableCheck | null, onRequestClose: () => void }) {
  const ref = useRef<HTMLDivElement>(null);
  const { openModal, closeModal } = useModals();
  const { isFetching, isError, data, refetch } = useTableRoll({ tableId: table?.table_check_id });

  useEffect(() => {
    refetch();
  }, [table, refetch]);

  function openPromptModal() {
    openModal(AiPromptModal, {prompt: `Roll results for table ${table?.table_name}: ${ref.current?.innerText ?? null}`, onRequestClose: closeModal})
  }

  let statusStr: ReactNode | null = null;
  if (isFetching) statusStr = <><Loader size='large' /></>;
  if (isError) statusStr = 'Error';

  return <>
    <FixedModal
      isOpen={table !== null}
      onRequestClose={onRequestClose}
      title={`Roll Results for ${table?.table_name}`}
      footer={<><button onClick={openPromptModal}>Narrate It!</button><button onClick={() => refetch()}>Reroll</button><button onClick={() => onRequestClose()}>Close</button></>}
    >
      <div ref={ref} className='ResultsBody'>
        {statusStr ? statusStr : 
          <>{data?.result && data.status === 'Success' && <ShowTableRollResults result={data.result} />}</>
        }
      </div>
    </FixedModal>
  </>;
}
