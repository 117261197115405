import { useHexDeleteMutation, useHexes } from 'hooks/hex-hooks';
import './HexList.scss';
import { Loader, Table, TableBody, TableCell, TableHead, TableRow } from '@aws-amplify/ui-react';
import IconButton from 'components/shared/IconButton';
import { Edit, Plus, Trash, SkipBack } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { isAdmin, useAccountInfo } from 'hooks/account-hooks';

function truncateDesc(desc?: string): string {
  const strToTruncate = desc ?? '';
  return `${strToTruncate.substring(0, 200)}${strToTruncate?.length > 200 ? '...' : ''}` ;
}

export default function HexList() {
  const navigate = useNavigate();
  const [accountInfo, isAccountLoading] = useAccountInfo();
  const { data, error, isLoading } = useHexes({userId: accountInfo?.userId});
  const deleteMutation = useHexDeleteMutation();
  const hexes = data?.results ?? [];

  function editHex(hexId?: string): void {
    navigate(`hex/${hexId ? hexId : 'new'}`);
  }

  if (error) return <>Error loading hexes</>;
  if (isLoading || isAccountLoading) return <><Loader /></>;
  
  return <div className='HexList'>
    <div className='HexList-topActions'>
      <button className='HexList-return' onClick={() => navigate('/hex-explorer')}><SkipBack />Return to Explorer</button>
      <button className='HexList-add' onClick={() => editHex()}>Add Hex<Plus /></button>
    </div>
    <Table
      className='HexList-table'
      highlightOnHover={true}
    >
      <TableHead>
        <TableRow>
          <TableCell as="th">Hex Name</TableCell>
          <TableCell as="th">Description</TableCell>
          <TableCell as="th">Terrain Difficulty</TableCell>
          <TableCell as="th"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {hexes.length > 0 ? hexes.map(hex => <>
          <TableRow key={hex.hex_id}>
            <TableCell>
              <div className='HexList-name'>
                <img className='HexList-image' src={`/${hex.image_url ?? 'hex-crawler-icon.png'}`} alt={`${hex.name} hex`} />
                {hex.name}
              </div>
            </TableCell>
            <TableCell>{truncateDesc(hex.description)}</TableCell>
            <TableCell>{hex.terrain_difficulty}</TableCell>
            <TableCell>
              <div className='HexList-actions'>
                {(hex.user_id !== null || isAdmin(accountInfo)) && <>
                  <IconButton onClick={() => deleteMutation.mutate(hex)}><Trash /></IconButton>
                  <IconButton onClick={() => editHex(hex.hex_id)}><Edit /></IconButton>
                </>}
              </div>
            </TableCell>
          </TableRow>
        </>) : <>
          <TableRow>
            <TableCell colSpan={4}>No hexes found</TableCell>
          </TableRow>
        </>}
      </TableBody>
    </Table>
  </div>;
}
