import { KeyValueStorageInterface } from 'aws-amplify/utils';
import JsCookie from 'js-cookie';

class CookieStorage implements KeyValueStorageInterface {
  path = '/';
  domain?: string
  expires = 365;
  sameSite?: 'strict' | 'lax' | 'none';
  secure = true;

  constructor(data: {
    path?: string,
    domain?: string,
    expires?: number,
    sameSite?: 'strict' | 'lax' | 'none',
    secure?: boolean
  } = {}) {
    const { path, domain, expires, sameSite, secure } = data;
    this.domain = domain;
    this.sameSite = sameSite;
    if (path) this.path = path;
    if (expires) this.expires = expires;
    if (secure) this.secure = secure;
    if (this.sameSite === 'none' && !this.secure) {
        throw new Error('sameSite = None requires the Secure attribute in latest browser versions.');
    }
  }
  async setItem(key: string, value: string) {
    JsCookie.set(key, value, this.getData());
    if (key.endsWith('accessToken')) {
      JsCookie.set('__session', value, this.getData());
    }
  }
  async getItem(key: string) {
    const item = JsCookie.get(key);
    return item ?? null;
  }
  async removeItem(key: string) {
    JsCookie.remove(key, this.getData());
    if (key.endsWith('accessToken')) {
      JsCookie.remove('__session', this.getData());
    }
  }
  async clear() {
    const cookie = JsCookie.get();
    const promises = Object.keys(cookie).map(key => this.removeItem(key));
    await Promise.all(promises);
  }
  getData() {
    return {
      path: this.path,
      expires: this.expires,
      domain: this.domain,
      secure: this.secure,
      sameSite: this.sameSite,
    };
  }
}

export { CookieStorage };
