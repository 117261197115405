import { KeyboardEvent } from "react";
import "./ToggleSwitch.scss";

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function. The props name, small, disabled
and optionLabels are optional.
Usage: <ToggleSwitch id="id" checked={value} onChange={checked => setValue(checked)}} />
*/

const ToggleSwitch = ({
  className,
  name,
  checked,
  onChange,
  optionLabels = ['Yes', 'No'],
  small,
  disabled
}: {
  className?: string,
  name?: string,
  checked: boolean,
  onChange: (val: boolean) => void,
  optionLabels?: string[],
  small?: boolean,
  disabled?: boolean
}) => {
  function handleKeyPress(e: KeyboardEvent) {
    if (e.keyCode !== 32) return;

    e.preventDefault();
    onChange(!checked);
  }

  return (
    <div className={`toggle-switch ${small ? "small-switch" : ""} ${className}`}>
      <label
        className="toggle-switch-label"
        tabIndex={disabled ? -1 : 1}
        onKeyDown={(e: KeyboardEvent) => handleKeyPress(e)}
      >
        <input
          type="checkbox"
          name={name}
          className="toggle-switch-checkbox"
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          disabled={disabled}
        />
        <span
          className={
            disabled
              ? "toggle-switch-inner toggle-switch-disabled"
              : "toggle-switch-inner"
          }
          data-yes={optionLabels[0]}
          data-no={optionLabels[1]}
          tabIndex={-1}
        />
        <span
          className={
            disabled
              ? "toggle-switch-switch toggle-switch-disabled"
              : "toggle-switch-switch"
          }
          tabIndex={-1}
        />
      </label>
    </div>
  );
};

export default ToggleSwitch;
