import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { TableCheck } from 'models/table-check';
import API from 'api';
import { ApiResponse } from 'models/api-response';

export const DEFAULT_TABLE_VALUES: TableCheck = {
  table_check_id: 'new',
  table_name: '',
  user_id: 'new',
  roll: 100,
  table_category: '',
  hidden: false,
  rows: []
};

const fetchTables = async (): Promise<{status: string, results: TableCheck[]}> => {
  return API
    .get('/tables')
    .then((res) => res.data).catch(e => {
      console.log('error', e);
    });
};

export const useTables = ({userId = 'global'}: {userId?: string} = {}) => {
  return useQuery({
    queryKey: ['randomTables', userId],
    queryFn: fetchTables,
    staleTime: Infinity,
  });
};

export const useTable = ({ tableId }: {tableId: string | undefined}) => {
  return useQuery<TableCheck>({
    queryKey: ['randomTable', tableId],
    staleTime: Infinity,
    queryFn: () => {
      return tableId ? API
        .get(`/tables/${tableId}`)
        .then((res) => res.data.result) :
      {...DEFAULT_TABLE_VALUES};
    },
  });
};

type PropertyMap = Record<string, TableCheckPropertyResult>;
type TableCheckPropertyResult = {
  key: string,
  parsed: boolean,
} & (
{
  value?: string | number,
  type: 'dice_roll'
} |
{
  value?: number,
  type: 'reroll_under'
} |
{
  value?: number,
  type: 'reroll_over'
} |
{
  value?: RollResults[],
  type: 'table_check'
} |
{
  value?: string,
  type: 'result_display'
} |
{
  value?: string,
  type: 'other'
}
)
export interface RollResults {
  tableName: string,
  result?: string,
  roll: number,
  properties: PropertyMap
}

const fetchRoll = async (tableId: string): Promise<ApiResponse<RollResults>> => {
  return API
    .post('/tables/roll', {tableId})
    .then((res) => res.data);
};

export const useTableRoll = ({ tableId }: {tableId: string | undefined}) => {
  return useQuery({
    queryKey: ['tableRoll'],
    queryFn: () => tableId ? fetchRoll(tableId) : {status: 'Error', result: null} as const,
    refetchOnWindowFocus: false,
  });
};

export const downloadTable = async (tableId: string): Promise<ApiResponse<string[]>> => {
  return API
    .get(`/tables/download/${tableId}`)
    .then((res) => res.data);
};

export const useTableDownload = ({ tableId }: {tableId: string}) => {
  return useQuery({
    queryKey: ['tableExport', tableId],
    queryFn: () => downloadTable(tableId),
    refetchOnWindowFocus: false,
  });
}

export const useTableRefresh = () => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries({ queryKey: ['randomTables'] });
}

export const useTableCreateMutation = () => {
  const refreshQueryList = useTableRefresh();
  return useMutation({
    mutationFn: (newTableData: TableCheck) => API.post('/tables', newTableData),
    onSuccess: refreshQueryList
  })
}

export const useTableUpdateMutation = () => {
  const queryClient = useQueryClient();
  const refreshQueryList = useTableRefresh();
  return useMutation({
    mutationFn: (updatedTableData: TableCheck) => API.put(`/tables/${updatedTableData.table_check_id}`, updatedTableData).then(data => {
      queryClient.invalidateQueries({ queryKey: ['randomTable', updatedTableData.table_check_id] })
      return data;
    }),
    onSuccess: refreshQueryList
  })
}

export const useTableDeleteMutation = () => {
  const refreshQueryList = useTableRefresh();
  return useMutation({
    mutationFn: (tableToDelete: TableCheck) => API.delete(`/tables/${tableToDelete.table_check_id}`),
    onSuccess: refreshQueryList  
  })
}

export const useTableUploadMutation = () => {
  const refreshQueryList = useTableRefresh();
  return useMutation({
    mutationFn: (csvData: string[][]) => API.post('/tables/upload', csvData),
    onSuccess: refreshQueryList
  })
}