import { useState } from 'react';
import './HexExplorer.scss'
import TravelOptions from './TravelOptions';
import { Speed } from 'utils/global-types';
import HexPicker from './HexPicker';
import HexResultsModal from './HexResultsModal';
import { Hex } from 'models/hex';
import IconButton from 'components/shared/IconButton';
import { Settings } from 'react-feather';
import SlideOutMenu from 'components/shared/SlideOutMenu';
import { useStickyState } from 'hooks/sticky-state';

export default function HexExplorer() {
  const [collapsed, setCollapsed] = useState(true);
  const [hexToExplore, setHexToExplore] = useState<Hex | null>(null);
  const [partySpeed, setPartySpeed] = useStickyState<Speed>('normal', 'partySpeed');
  const [exploring, setExploring] = useStickyState(true, 'exploring');

  return <>
    <div className='HexExplorer-header'>
      What kind of hex do you want to explore?
      <IconButton className='HexExplorer-settings' onClick={() => setCollapsed(!collapsed)}><Settings /></IconButton>
    </div>
    <HexPicker exploreHex={setHexToExplore} />
    <HexResultsModal hex={hexToExplore} speed={partySpeed} exploring={exploring} onRequestClose={() => setHexToExplore(null)} />
    <SlideOutMenu className='HexExplorer-sidebar' collapsed={collapsed} setCollapsed={setCollapsed} side='right' width={300}>
      <TravelOptions partySpeed={partySpeed} setPartySpeed={setPartySpeed} exploring={exploring} setExploring={setExploring} />
    </SlideOutMenu>
  </>;
}