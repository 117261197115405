export function cloneArrayExceptId<T extends { [key in K]?: string } & Record<string, any>, K extends string>(
  arr: T[],
  id: string,
  idField: K,
  replaceWith: (old: T, idx: number) => T
): T[] {
  return [...arr.map((r, i) => {
    if (r[idField] === id) {
      return replaceWith(r, i);
    }
    return {...r};
  })]
}