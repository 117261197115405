import { ReactNode } from 'react';
import './FixedModal.scss';
import Modal from 'react-modal';
import { X } from 'react-feather';
import IconButton from '../IconButton';

export default function FixedModal({
  isOpen,
  className,
  children,
  onRequestClose,
  title,
  footer
}: {
  children: ReactNode,
  className?: string,
  isOpen: boolean,
  onRequestClose?: () => void,
  title?: string | ReactNode,
  footer?: ReactNode
}) {
  return <>
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={`FixedModal ${className}`}
      overlayClassName='FixedModal-overlay'
    >
      <div className='ModalHeader'>
        {title? <h2>{title}</h2>
        :<></>}
        <span className='CloseButton'><IconButton onClick={onRequestClose}><X /></IconButton></span>
      </div>
      
      <div className='ModalBody'>
        {children}
      </div>
      {footer?
        <div className='ModalFooter'>
          {footer}
        </div>
      :<></>}
    </Modal>
  </>;
}
