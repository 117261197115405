import './RandomTables.scss';
import { TableCheck } from 'models/table-check';
import { useState } from 'react';
import RandomTablesResultModal from './ResultModal/ResultModal';
import { Plus, Upload } from 'react-feather';
import { useTables } from 'hooks/random-table-hooks';
import { useNavigate } from 'react-router-dom';
import { useAccountInfo } from 'hooks/account-hooks';
import { Button, Loader } from '@aws-amplify/ui-react';
import RandomTableFilters from './RandomTableFilters';
import ToggleSwitch from 'components/shared/ToggleSwitch';
import { useStickyState } from 'hooks/sticky-state';
import useScreenSize from 'hooks/screen-size';
import RandomTable from './RandomTable/RandomTable';

export default function RandomTables() {
  const [screenSize] = useScreenSize();
  const [filteredTables, setFilteredTables] = useState<TableCheck[]>([]);
  const [accountInfo, isAccountLoading] = useAccountInfo();
  const [tableToRoll, setTableToRoll] = useState<TableCheck | null>(null);
  const [editMode, setEditMode] = useStickyState(false, 'RandomTables-editMode');
  const navigate = useNavigate();
  const { error, data, isLoading } = useTables({userId: accountInfo?.userId});

  const isEditMode = screenSize !== 'mobile' && editMode;
  const showEditOption = screenSize !== 'mobile' && !!accountInfo;

  const showUpload = true;

  const tables: TableCheck[] = data?.results ?? [];

  function editTable(tableId?: string): void {
    navigate(`table/${tableId ? tableId : 'new'}`);
  }

  if (isLoading || isAccountLoading) return <Loader />
  if (error) {
    return <p>Error fetching data: {error.message}</p>
  }
  return <div className={`${isEditMode ? 'RandomTable-editMode' : 'RandomTable-playMode'}`}>
    <div className='RandomTable-controlBar'>
      <RandomTableFilters tables={tables} onFiltered={setFilteredTables} />
      {isEditMode &&
      <div className='RandomTable-massActions'>
        <Button className='RandomTable-add' onClick={() => editTable()}>Add <Plus /></Button>
        {showUpload && 
          <Button className='RandomTable-upload' onClick={() => navigate('upload')}>Upload <Upload /></Button>
        }
      </div>}
      <div>
        {showEditOption &&
          <label className='RandomTables-editToggle'>
            Edit Tables?
            <ToggleSwitch
              checked={editMode}
              onChange={() => setEditMode(!editMode)}
            />
          </label>
        }
      </div>
    </div>
      <ul className='RandomTable-list'>
        {filteredTables.map((result: TableCheck) => 
          <>
            <RandomTable key={result.table_check_id} table={result} setTableToRoll={setTableToRoll} editing={isEditMode}/>
          </>
        )}
        {(isEditMode || !accountInfo) && <li className='RandomTable-item RandomTable-add-item' key='add-table' onClick={() => editTable()}>
          +
        </li>}
      </ul>
    <RandomTablesResultModal table={tableToRoll} onRequestClose={() => setTableToRoll(null)} />
  </div>;
}
