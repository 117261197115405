import { ChangeEvent, useRef, useState } from 'react';
import './UploadTables.scss';
import { Button, DropZone, Flex, Loader, Text, VisuallyHidden } from '@aws-amplify/ui-react';
import Papa from 'papaparse';
import { useTableUploadMutation } from 'hooks/random-table-hooks';
import { useNavigate } from 'react-router-dom';

const acceptedFileTypes = ['text/csv'];

export default function UploadTables() {
  const [uploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const hiddenInput = useRef<HTMLInputElement>(null);
  const uploadTables = useTableUploadMutation();
  const navigate = useNavigate();

  const onFilePickerChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (!files || files.length === 0) {
      return;
    }
    setFiles(Array.from(files).filter(f => acceptedFileTypes.includes(f.type)));
  };

  function uploadCsvData() {
    setIsUploading(true);
    Papa.parse<string[]>(files[0], {error: (e) => {
      setIsUploading(false);
      setErrorMessage(e.message);
    }, complete: function(results) {
      uploadTables.mutate(results.data, {onSuccess: () => {
        setIsUploading(false);
        navigate('/random-tables');
      }, onError: e => {
        setIsUploading(false);
        setErrorMessage(e.message);
      }});
    }})
  }

  return <>
    <p>You can upload a CSV file to create one or more tables for you automatically! The format looks something like this:</p>
    <div className='UploadTables-sample'>
    <code>{`d9 Quick Town NPCs
,ALCHEMIST
,,d6 The alchemist is...
,,,An apothecary.
,,,A hedge wizard.
,,,An herbalist.
,,,A poisonmaker.
,,,A potioneer.
,,,A pyromancer.
,,d4 The alchemist is looking for...
,,,Delivery help.
,,,New recipes.
,,,Purchasers.
,,,Rare ingredients.
,,d4 The alchemist carries...
,,,Several vials of acid.
,,,Several curatives.
,,,An unusual potion.
,,,A pyrophoric substance.
,CRIMINAL
,,d6 The criminal is...
,,,An assassin.
,,,A con artist.
,,,A gambler.
,,,A poacher.
,,,A smuggler.
,,,A thief.
,,d6 The criminal is looking for...
,,,Accomplices for a specific task.
,,,Revenge against a rival criminal.
,,,An easy mark.
,,,Extra muscle for some work.
,,,Rumors that may lead to a big score.
,,,A rowdy evening of carousing.
,,d6 The criminal carries...
,,,A crossbow with poisoned darts.
,,,Several daggers.
,,,A short sword.
,,,A lucky charm.
,,,The token of a love.
,,,Letters for blackmail.`}
    </code></div>
    <p>A new table should always start off with `d#`, for the dice size to roll, followed by the name of the table. Rows for that table are indented one column. Any properties for a row are indented one more column. If a property begins with `d#`, it is treated as a sub-table, and will be created and hidden.</p>
    <DropZone
        acceptedFileTypes={acceptedFileTypes}
        onDropComplete={({ acceptedFiles }) => {
          setFiles(acceptedFiles);
        }}
      >
        <Flex direction="column" alignItems="center">
          <Text>Drag files here or</Text>
          <Button size="small" onClick={() => hiddenInput.current?.click()}>
            Browse
          </Button>
        </Flex>
        <VisuallyHidden>
          <input
            type="file"
            tabIndex={-1}
            ref={hiddenInput}
            onChange={onFilePickerChange}
            multiple={true}
            accept={acceptedFileTypes.join(',')}
          />
        </VisuallyHidden>
      </DropZone>
      {files.map((file) => (
        <Text key={file.name}>{file.name}</Text>
      ))}
      <Button onClick={uploadCsvData} disabled={files.length === 0 || uploading}>Upload</Button>
      {uploading && <Loader />}
      {errorMessage && <>
        <p className='ErrorText'>{errorMessage}</p>
      </>}
  </>;
}
