import { TableCheck } from 'models/table-check';
import './RandomTable.scss';
import IconButton from 'components/shared/IconButton';
import { Edit, Trash } from 'react-feather';
import Dice from 'components/CustomIcons/Dice';
import { useTableDeleteMutation } from 'hooks/random-table-hooks';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';

export default function RandomTable({table, editing = false, setTableToRoll}: {editing?: boolean, table: TableCheck, setTableToRoll(table: TableCheck): void}) {
  const navigate = useNavigate();
  const deleteMutation = useTableDeleteMutation();

  
  const tableCategories = useMemo(() => table.table_category.split(','), [table.table_category]);
  const categoriesInUse = useMemo(() => tableCategories.reduce((arr, tc) => {
      if (!arr.hasOwnProperty(tc)) {
        arr[tc] = 0;
      }
      arr[tc]++;
    return arr;
  }, {} as Record<string, number>), [tableCategories]);

  const categoryClass = useMemo(() => {
    if (categoriesInUse.encounter > 0) return 'RandomTable-encounter';
    if (categoriesInUse.exploration > 0) return 'RandomTable-exploration';
    if (categoriesInUse.loot > 0) return 'RandomTable-loot';
    if (categoriesInUse.npc > 0) return 'RandomTable-npc';
    if (categoriesInUse.weather > 0) return 'RandomTable-weather';
    return 'RandomTable-other';
  }, [categoriesInUse]);
  // 'encounter', 'exploration', 'loot', 'npc', 'weather'


  function editTable(tableId?: string): void {
    navigate(`table/${tableId ? tableId : 'new'}`);
  }

  return <li className={`RandomTable-item ${categoryClass}`} key={table.table_check_id}>
  <span className='RandomTable-name'>
    {table.table_name}
    {table.source && <span className='RandomTable-source'>Source: <a href={table.source.url} target='_blank' rel='noreferrer'>{table.source.label}</a></span>}
  </span>
  <div className='RandomTable-actions'>
    {editing ? <>
      <IconButton className='DesktopOnly' onClick={() => deleteMutation.mutate(table)}><Trash /></IconButton>
      <IconButton className='DesktopOnly' onClick={() => editTable(table.table_check_id)}><Edit /></IconButton>
    </> : <>
      <IconButton className='RandomTable-dice' onClick={() => setTableToRoll(table)}><Dice /></IconButton>
    </>}
  </div>
</li>;
}
