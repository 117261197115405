import './RandomTableForm.scss';
import { TableCheck } from 'models/table-check';
import { ChangeEvent, useEffect, useState } from 'react';
import { TableCheckRow } from 'models/table-check-row';
import EditTableRows from 'components/RandomTables/RandomTableForm/EditTableRows';
import NumberInput from 'components/shared/NumberInput';
import { useNavigate } from 'react-router-dom';
import { downloadTable, useTableCreateMutation, useTableUpdateMutation, DEFAULT_TABLE_VALUES, useTable } from 'hooks/random-table-hooks';
import Creatable from 'react-select/creatable';
import ToggleSwitch from 'components/shared/ToggleSwitch';
import { isAdmin, useAccountInfo } from 'hooks/account-hooks';
import Papa from 'papaparse';
import { download } from 'utils/download';
import GuestInfo from 'components/GuestInfo';

export default function EditRandomTable({tableId, onExit, onSave}: {tableId?: string, onExit?: () => void, onSave?: (tableId: string) => void}) {
  const [accountInfo] = useAccountInfo();
  const navigate = useNavigate();
  const [showExport] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [rows, setRows] = useState<TableCheckRow[]>([]);
  const [tableData, setTableData] = useState<TableCheck>({...DEFAULT_TABLE_VALUES});
  const { data, isLoading, isError } = useTable({tableId});

  const createMutation = useTableCreateMutation();
  const updateMutation = useTableUpdateMutation();

  useEffect(() => {
    if (data && (!hasLoaded || tableId !== undefined) ) {
      setTableData({...data});
      setRows([...data.rows]);
      setHasLoaded(true);
    }
  }, [data, data?.rows, hasLoaded, tableId]);

  if (isLoading) return <>Loading...</>;
  if (isError) return <>Error!</>;
  if (!accountInfo) return <GuestInfo>
    <p>To create a new table, you need an account to save it to. Once you have a free account, you can create all the custom tables you want!</p>
  </GuestInfo>;

  const tableCategoryArray = data?.table_category && data.table_category.length > 0 ? (data?.table_category ?? '').split(',') : [];
  const categoryFilterOptions: string[] = [...tableCategoryArray, 'encounter', 'exploration', 'loot', 'npc', 'weather'];
  type CategoryOption = {value: string, label: string};
  const categoryOptions: CategoryOption[] = categoryFilterOptions.map(category => ({ value: category, label: category}));
  const categoryOptionMap: Record<string, CategoryOption> = categoryOptions.reduce((map, option) => ({...map, [option.value]: option}), {} as Record<string, CategoryOption>);

  function setCategory(categories?: string[]) {
    setTableData({ ...tableData, table_category: categories?.join(',') ?? 'other' });
  }

  function isKeyValid(key: string): key is keyof TableCheck {
    return key in tableData;
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (!isKeyValid(name)) return;
    const typedValue = event.target.type === 'number' ? +value : value;
    syncChange(name, typedValue);
  };

  const handleSourceChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const newSource = {...(tableData.source ?? {label: '', url: ''}), [name]: value};
    syncChange('source', newSource);
  };

  const syncChange = <T extends keyof TableCheck>(name: T, value: TableCheck[T]) => {
    setTableData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  function saveTable() {
    const user_id = tableData.user_id === 'new' ? accountInfo?.userId : tableData.user_id;
    const tableToSave = { ...tableData, rows, user_id };
    const saveMethod = tableToSave.table_check_id === 'new' ? createMutation.mutate : updateMutation.mutate;
    saveMethod(tableToSave, {
      onSuccess: (data) => {
        if (data.data.status === 'Error') {
          setErrorMessage(data.data.result.message);
        } else {
          onSave?.(data.data.result.table_check_id);
          exitTableForm();
        }
      }
    });
  }

  function exitTableForm() {
    if (onExit) onExit();
    else navigate('/random-tables');
  }

  async function exportTable() {
    const response = await downloadTable(tableId!);
    if (response.status === 'Success') {
      const csvStr = Papa.unparse(response.results);
      download(`${tableData.table_name}.csv`, csvStr);
    }
  }

  return <>
    <div className='TableForm-fields'>
      <label>Table Name:</label>
      <input type='text' name='table_name' value={tableData.table_name} onChange={handleChange} />
      <label>Dice roll:</label>
      <NumberInput 
        name='roll'
        defaultValue={tableData.roll}
        min={Math.max(rows.length, 1)}
        max={999}
        onChange={(changedVal: number) => {
          syncChange('roll', changedVal);
        }}
      />
      {hasLoaded && <>
        <label>Category:</label>
        <Creatable
          className='TableForm-value'
          isMulti={true}
          options={categoryOptions}
          defaultValue={tableCategoryArray.map(c => categoryOptionMap[c])}
          onChange={(opt) => setCategory(
            [...(opt?.values() ?? [])].map(o => o.value)
          )}
        />
      </>}
      <label>Hidden?:</label>
      <ToggleSwitch
        className='TableForm-value'
        checked={tableData.hidden}
        onChange={() => syncChange('hidden', !tableData.hidden)}
      />
      {accountInfo && isAdmin(accountInfo) && <>
        <label>Global?:</label>
        <ToggleSwitch
          className='TableForm-value'
          checked={tableData.user_id === 'global'}
          onChange={() => syncChange('user_id', tableData.user_id === 'global' ? accountInfo.userId : 'global')}
        />
        {tableData.user_id === 'global' && <>
          <label>Source Label:</label>
          <input type='text' name='label' value={tableData.source?.label} onChange={handleSourceChange} />
          <label>Source URL:</label>
          <input type='text' name='url' value={tableData.source?.url} onChange={handleSourceChange} />
        </>}
      </>}
      <EditTableRows tableData={tableData} rows={rows} setRows={setRows} />
    </div>
    <footer>
      <button onClick={exitTableForm}>Cancel</button>
      <button onClick={saveTable}>Save</button>
      {showExport && 
        <button onClick={exportTable}>Export</button>
      }
      {errorMessage && <>
        <p className='ErrorText'>{errorMessage}</p>
      </>}
    </footer>
  </>;
}
