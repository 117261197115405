import { useEffect, useRef } from 'react';
import './NumberInput.scss';

export default function NumberInput({name, defaultValue = 0, min, max, disabled, onChange}: {name?: string, defaultValue?: number, min?: number, max?: number, disabled?: boolean, onChange?: (val: number) => void}) {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.value = `${defaultValue}`;
    }
  }, [defaultValue]);

  function handlePossibleChange() {
    if (ref.current) {
      let val = ref.current.value.length > 0 ? Number.parseInt(ref.current.value) : defaultValue;
      if (min !== undefined) {
        val = Math.max(min, val);
        ref.current.value = `${val}`;
      }
      if (max !== undefined) {
        val = Math.min(max, val);
        ref.current.value = `${val}`;
      }
      if (val !== defaultValue) {
        onChange?.(val);
      }
    }
  }

  return <>
    <input
      type='number'
      name={name}
      defaultValue={defaultValue}
      min={min}
      max={max}
      disabled={disabled}
      ref={ref}
      onMouseUp={handlePossibleChange}
      onBlur={handlePossibleChange}
    />
  </>;
}
