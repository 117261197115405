import ShowTableRollResults from 'components/shared/ShowTableRollResults';
import './HexResultsModal.scss';
import FixedModal from 'components/shared/FixedModal';
import { useTableRoll } from 'hooks/random-table-hooks';
import { Hex, TerrainDifficulty } from 'models/hex';
import { useEffect, useRef } from 'react';
import { Speed } from 'utils/global-types';
import { useModals } from 'hooks/modal-stack';
import AiPromptModal from 'components/NarrateAI/AiPromptModal';

const explorationTimes: Record<Speed, Record<TerrainDifficulty, number>> = {
  xslow: { easy: 3, medium: 5, hard: 5 },
  slow: { easy: 2, medium: 4, hard: 4 },
  normal: { easy: 1, medium: 3, hard: 3 },
  fast: { easy: 1, medium: 2, hard: 2 },
  xfast: { easy: 1, medium: 1, hard: 1 },
}

const travelTimes: Record<Speed, Record<TerrainDifficulty, number>> = {
  xslow: { easy: 11, medium: 16, hard: 32 },
  slow: { easy: 8, medium: 12, hard: 24 },
  normal: { easy: 5, medium: 8, hard: 16 },
  fast: { easy: 4, medium: 6, hard: 12 },
  xfast: { easy: 3, medium: 5, hard: 10 },
}

export default function HexResulstModal({ hex, speed, exploring, onRequestClose }: { hex: Hex | null, speed: Speed, exploring: boolean, onRequestClose: () => void }) {
  const ref = useRef<HTMLDivElement>(null);
  const { openModal, closeModal } = useModals();
  const { isLoading, isError, data, refetch } = useTableRoll({ tableId: hex?.table_check_id });

  useEffect(() => {
    refetch();
  }, [hex, refetch]);

  function openPromptModal() {
    openModal(AiPromptModal, {prompt: ref.current?.innerText ?? null, onRequestClose: closeModal})
  }

  const explorationTime = hex ? `${explorationTimes[speed][hex.terrain_difficulty]} days` : '';
  const travelTime = hex ? `${travelTimes[speed][hex.terrain_difficulty]} hours` : '';
  const elapsedTime = exploring ? explorationTime : travelTime;

  let statusStr: string | null = null;
  if (isLoading) statusStr = 'Loading...';
  if (isError) statusStr = 'Error';

  return <>
    <FixedModal
      isOpen={hex !== null}
      onRequestClose={onRequestClose}
      title='Exploration Results'
      footer={<><button onClick={openPromptModal}>Narrate It!</button><button onClick={() => refetch()}>Reroll</button><button onClick={() => onRequestClose()}>Close</button></>}
    >
      <div ref={ref} className='ResultsBody'>
        {statusStr ? statusStr : 
          <>
            {hex?.table_check_id !== undefined && data?.result && data.status === 'Success' && <ShowTableRollResults result={data.result} />}
            <p className='resultName'>Elapsed Time: </p>
            <div className='resultValue'>{elapsedTime}</div>
          </>
        }
      </div>
    </FixedModal>
  </>;
}
