import { useParams } from 'react-router-dom';
import './EditRandomTable.scss';
import RandomTableForm from 'components/RandomTables/RandomTableForm';

export default function EditRandomTable() {
  const { tableId: urlTableId } = useParams();
  const tableId = urlTableId && urlTableId !== 'new' ? urlTableId : undefined;

  return <RandomTableForm tableId={tableId} />;
}
