import './ToggleGroup.scss';

export default function ToggleGroup<T extends string | number>({
  options,
  value,
  className,
  render,
  onChange
}: {
  options: T[],
  value: T,
  className?: string,
  render?: (val: T) => string
  onChange: (newVal: T) => void
}) {
  function getDisplay(val: T): string {
    return render?.(val) ?? `${val}`;
  }
  return <div className={`ToggleGroup ${className ?? ''}`}>
    {options.map(opt => <>
      <button key={opt} value={opt} className={`ToggleGroup-button ${opt === value ? 'active' : ''}`} onClick={() => onChange(opt)}>{getDisplay(opt)}</button>
    </>)}
  </div>;
}
