import { useStickyState } from 'hooks/sticky-state';
import './RandomTableFilters.scss';
import IconButton from 'components/shared/IconButton';
import { Settings } from 'react-feather';
import Select from 'react-select';
import ToggleSwitch from 'components/shared/ToggleSwitch';
import { TableCheck } from 'models/table-check';
import { useCallback, useEffect, useState } from 'react';
import SlideOutMenu from 'components/shared/SlideOutMenu';

interface TableFilters {
  filter: string,
  showHidden: boolean,
  showGlobal: boolean,
  tableCategories: string[],
}

export default function RandomTableFilters({tables, onFiltered}:{tables: TableCheck[], onFiltered: (filtered: TableCheck[]) => void}) {
  const [collapsed, setCollapsed] = useState(true);
  const [filters, setFilters] = useStickyState<TableFilters>({
    filter: '',
    showHidden: false,
    showGlobal: true,
    tableCategories: []
  }, 'filters');

  const filterTables = useCallback((newFilters: TableFilters) => {
    onFiltered(tables.filter(t => tableCheckFilter(t, newFilters)));
  }, [onFiltered, tables]);

  useEffect(() => {
    filterTables(filters);
  }, [tables, filterTables, filters]);

  function tableCheckFilter(tc: TableCheck, filterData: TableFilters) {
    const { filter, showHidden, showGlobal, tableCategories } = filterData;
    if (!tc.table_name.toLowerCase().includes(filter.toLowerCase())) return false;
    if (!showHidden && tc.hidden) return false;
    if (!showGlobal && tc.user_id === 'global') return false;
    if (tableCategories.length > 0) {
      const categories = tc.table_category.split(',');
      for (let i = 0; i < categories.length; i++) {
        if (tableCategories.includes(categories[i])) return true;
      }
      return false;
    }
    return true;
  }

  function updateFilter(name: string, value: unknown) {
    const newFilters = {...filters, [name]: value};
    setFilters(newFilters);
    filterTables(newFilters);
  }

  const categoriesInUse = tables.reduce((arr, table) => {
    const tableCategories = table.table_category.split(',');
    tableCategories.forEach(tc => {
      if (!arr.includes(tc)) {
        arr.push(tc);
      }
    });
    return arr;
  }, [] as string[]);
  type CategoryOption = {value: string, label: string};
  const categoryOptions: CategoryOption[] = categoriesInUse.map(category => ({ value: category, label: category}));
  const categoryOptionMap: Record<string, CategoryOption> = categoryOptions.reduce((map, option) => ({...map, [option.value]: option}), {} as Record<string, CategoryOption>);

  return <>
    <div className='RandomTable-filters'>
      <div>
        <label>Filter: </label>
        <input className='RandomTable-filterValue' type='text' value={filters.filter} onChange={e => updateFilter('filter', e.target.value)} />
      </div>
      <div>
        <label className='filterLabel'>Category: </label>
        <Select
          className='RandomTable-filterValue'
          isMulti={true}
          options={categoryOptions}
          defaultValue={filters.tableCategories.map(c => categoryOptionMap[c])}
          onChange={(opt) => updateFilter('tableCategories',
            [...(opt?.values() ?? [])].map(o => o.value)
          )}
        />
      </div>
      <IconButton className='RandomTable-filterButton' ariaLabel='Filter Settings' onClick={() => setCollapsed(!collapsed)}><Settings /></IconButton>
    </div>
    <SlideOutMenu className='RandomTable-sidebarFilters' collapsed={collapsed} setCollapsed={setCollapsed} side='right' width={300}>
      <div>
        <label className='filterLabel'>Show Hidden?: </label>
        <ToggleSwitch
          checked={filters.showHidden}
          onChange={() => updateFilter('showHidden', !filters.showHidden)}
        />
      </div>
      <div>
        <label className='filterLabel'>Show Global?: </label>
        <ToggleSwitch
          checked={filters.showGlobal}
          onChange={() => updateFilter('showGlobal', !filters.showGlobal)}
        />
      </div>
    </SlideOutMenu>
  </>;
}
