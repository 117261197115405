import { TableCheckRow } from 'models/table-check-row';
import './EditTableRow.scss';
import { TableCheck } from 'models/table-check';
import NumberInput from 'components/shared/NumberInput';
import { ChangeEvent, useState } from 'react';
import { Trash, GitMerge } from 'react-feather';
import IconButton from 'components/shared/IconButton';
import EditTableRowProperties from '../EditTableRowProperties';
import { cloneArrayExceptId } from 'utils/cloning';

export default function EditTableRow({
  row,
  idx,
  tableData,
  rows,
  disabled,
  numberDisabled,
  onRowChange,
  onRowDelete,
}: {
  row: TableCheckRow,
  idx: number,
  tableData: TableCheck,
  rows: TableCheckRow[],
  disabled?: boolean,
  numberDisabled?: boolean,
  onRowChange?: (r: TableCheckRow) => void,
  onRowDelete?: (r: TableCheckRow) => void,
}) {
  const [showProps, setShowProps] = useState((row.properties ?? []).length > 0);
  const endRange = row.max_result;
  const startRange = idx > 0 ? rows[idx-1].max_result+1 : 1;
  const prefix = `${startRange}-`;
  const min = 1;
  const max = tableData.roll;

  return <li className='TableForm-row' key={row.table_check_row_id}>
    <span className='TableForm-rowNumber'>{prefix}<NumberInput 
        name='endRange'
        defaultValue={endRange}
        min={min}
        max={max}
        disabled={disabled || numberDisabled}
        onChange={(changedVal: number) => {
          const newVal = Math.max(Math.min(changedVal, max), min);
          if (newVal !== row.max_result) {
            onRowChange?.({
              ...row,
              max_result: newVal
            });
          }
        }}
      />
    </span>
    <input
      type='text'
      name='rowName'
      value={row.row_name}
      disabled={disabled}
      className='TableForm-rowName'
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        if (row.row_name !== e.target.value ) {
          onRowChange?.({
            ...row,
            row_name: e.target.value
          });
        }
      }}
    />
    <IconButton disabled={disabled} className='TableForm-DeleteButton' onClick={() => onRowDelete?.(row)}>
      <Trash />
    </IconButton>
    <IconButton disabled={disabled} className='TableForm-SettingsButton' onClick={() => setShowProps(!showProps)}>
      <GitMerge />{row.properties?.length ?? 0}
    </IconButton>
    {showProps && <>
      <div className='EditTableRow-ShowProps'>
        <EditTableRowProperties
          className='TableForm-Settings'
          properties={row.properties}
          onPropertyChanged={prop => {
            let replaced = false;
            const newProperties = cloneArrayExceptId(row.properties ?? [], prop.table_check_property_id, 'table_check_property_id', () => {
              replaced = true;
              return prop;
            });
            if (!replaced) {
              newProperties.push(prop);
            }
            onRowChange?.({...row, properties: newProperties });
          }}
          onPropertyDeleted={prop => {
            const newRow = {...row, properties: row.properties?.filter(arrProp => arrProp.table_check_property_id !== prop.table_check_property_id) };
            onRowChange?.(newRow);
          }}
        />
      </div>
    </>}
  </li>;
}
