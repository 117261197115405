import RandomTableForm from '../RandomTableForm';
import './EditTableModal.scss';
import FixedModal from 'components/shared/FixedModal';

export default function RandomTablesEditTableModal({tableId, open, onRequestClose, onSave}: {tableId?: string, open: boolean, onRequestClose?: () => void, onSave?: (tableId: string) => void}) {
  return <>
    <FixedModal
      isOpen={open}
      onRequestClose={onRequestClose}
      title='Edit Table'
      className={`${open ? '' : 'hidden'}`}
    >
      <RandomTableForm tableId={tableId} onExit={onRequestClose} onSave={onSave} />
    </FixedModal>
  </>;
}
