import GuestInfo from 'components/GuestInfo';
import IconButton from 'components/shared/IconButton';
import SlideOutMenu from 'components/shared/SlideOutMenu';
import { useAccountInfo } from 'hooks/account-hooks';
import { useRef, useState } from 'react';
import { Settings } from 'react-feather';
import './NarrateAI.scss';
import NarrateSettings from './NarrateSettings';
import AiPromptModal from './AiPromptModal';
import { useModals } from 'hooks/modal-stack';
import { Button, Loader } from '@aws-amplify/ui-react';
import { signInWithRedirect } from 'aws-amplify/auth';

export default function NarrateAI() {
  const { openModal, closeModal } = useModals();
  const ref = useRef<HTMLTextAreaElement>(null);
  const [accountInfo, isLoading] = useAccountInfo();
  const [collapsed, setCollapsed] = useState(true);
  const restricted = !accountInfo && process.env.REACT_APP_RESTRICT_AI === 'true';

  function openPromptModal() {
    openModal(AiPromptModal, {prompt: ref.current?.value ?? null, onRequestClose: closeModal})
  }

  if (isLoading) return <Loader />
  if (restricted) {
    return <GuestInfo>
      <div className='NarrateAI-guest'>
        <p>NarrateAI is a tool for generating narration for your game, when you get a little stuck on your creativity. It allows you to set up some general background on your game or campain, and then generate narration for any given event.</p>
        <p>For example, you can set up your background information like this:</p>
        <img 
          alt='How long would you like your average narration to be? Shorter (2-3 sentences). Campaign background information: I am running a pathfinder first edition game of the Wrath of the Righteous adventure path. I have four players: Kyra the Cleric, Ezren the Wizard, Merisiel the Rogue, and Valeros the Fighter.'
          src='/ai/shorter-narration-settings.png' 
        />
        <p>And then when you narrate table roll output, it will look like this:</p>
        <img
          alt='As you cautiously proceed down the dimly lit corridor, a putrid stench fills the air, assaulting your nostrils. Suddenly, from the dark corners of the chamber, five snarling ghouls emerge, their eyes glowing with a malevolent hunger. Among them, a towering form looms - a ghast, its elongated claws dripping with a vile greenish substance. The fiendish creatures fix their gaze upon you, ready to unleash their relentless assault.'
          src='/ai/shorter-ghouls-ghasts-narration.png'
        />
        <p>You can instead set up your background information like this:</p>
        <img
          alt='How long would you like your average narration to be? Average (4-5 sentences). Campaign background information: I am running a pathfinder first edition game of the Wrath of the Righteous adventure path. I have four players: Kyra the Cleric, Ezren the Wizard, Merisiel the Rogue, and Valeros the Fighter. Try to use their names during any narration.'
          src='/ai/longer-narration-settings.png'
        />
        <p>And then when you narrate table roll output, it will look like this:</p>
        <img
          alt='As Kyra, Ezren, Merisiel, and Valeros bravely descend into the depths of the dungeon, a chilling silence fills the air. Suddenly, the flickering torchlight reveals a ghastly sight before them. A pack of five ghouls emerges from the shadows, their pallid skin and sunken eyes reflecting their insatiable hunger. Leading this dreadful pack is a towering figure, an imposing ghast, with its long, rotting claws and a stench of decay wafting through the air. The partys hearts race as they realize the gravity of the situation. The ghouls twitch with anticipation, ready to feast upon the very essence of the living. The fate of our heroes hangs in the balance as they engage this gruesome horde in a desperate battle for survival.'
          src='/ai/longer-ghouls-ghasts-narration.png'
        />
        <p>It also allows you to put in any narration prompt you want, and get custom narration:</p>
        <img
          alt='Prompt: A longer narration of a campaign introduction'
          src='/ai/introduction-narration-prompt.png'
        />
        <img
          alt='Narration: As the sun sets over the scenic city of Kenabres, the once vibrant streets now bear the scars of conflict. You gather at the bustling tavern known as the "Golden Griffin," where tales of your valor have been spreading like wildfire. Kyra, the stalwart cleric with windswept red hair, stands at the center of attention, her voice carrying the weight of the divine as she weaves tales of hope and redemption. Ezren, the sage wizard with a book permanently in hand, listens intently, occasionally interjecting with arcane knowledge, his sharp intellect shimmering behind his spectacles. Merisiel, the nimble rogue with midnight-hued eyes, leans against a nearby pillar, her smile hiding the secrets of countless successful burglaries. And Valeros, the brawny fighter with a sword slung across his back, towers over the others, impressing onlookers with tales of his daring exploits. As the room quiets down, you feel a stirring in your hearts, knowing that destiny has called you together for a purpose far grander than personal glory-to face the encroaching darkness and forge a path towards redemption.'
          src='/ai/introduction-narration-output.png'
        />
      </div>
    </GuestInfo>
  }
  return <>
    <div className='NarrateAI-header'>
      What would you like narrated?
      <IconButton className='NarrateAI-settings' onClick={() => setCollapsed(!collapsed)}><Settings /></IconButton>
    </div>
    <div className='NarrateAI-body'>
      <textarea ref={ref} rows={6}></textarea>
      <div>
        <button onClick={openPromptModal}>Narrate It!</button>
      </div>
    </div>
    <SlideOutMenu className='NarrateAI-sidebar' collapsed={collapsed} setCollapsed={setCollapsed} side='right' width={'80%'}>
      {accountInfo ? 
        <NarrateSettings onSave={() => setCollapsed(true)} /> :
        <>
          <p className='NarrateAI-sidebar-cta'>
            With an account, you can customize your narration options by providing custom campaign context or by specifying how long you prefer your narration to be. If you'd like to have a customized narration experience, log in to your free account!
          </p>
          <Button variation='primary' onClick={() => signInWithRedirect()}>Log in</Button>
        </>
      }
    </SlideOutMenu>
  </>;
}
