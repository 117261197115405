import { useAccountInfo } from 'hooks/account-hooks';
import './TravelOptions.scss';
import ToggleSwitch from 'components/shared/ToggleSwitch';
import Dropdown from 'react-dropdown';
import { useNavigate } from 'react-router-dom';
import { Speed, partySpeedDisplayMap } from 'utils/global-types';

const partySpeedOptions = (Object.keys(partySpeedDisplayMap) as Speed[]).map(speed => ({ value: speed, label: partySpeedDisplayMap[speed]}));

export default function TravelOptions({ partySpeed, setPartySpeed, exploring, setExploring }: { partySpeed: Speed, setPartySpeed: (speed: Speed) => void, exploring: boolean, setExploring: (exploring: boolean) => void }) {
  const navigate = useNavigate();
  const [accountInfo] = useAccountInfo();

  return <>
    <div className='configOption'>
      <label>Party Speed: </label>
      <Dropdown options={partySpeedOptions} onChange={(opt) => setPartySpeed(opt.value as Speed)} value={partySpeed} placeholder="Select an option" />
    </div>
    <div className='configOption'>
      <label>Fully Exploring?</label>
      <ToggleSwitch
        checked={exploring}
        onChange={() => setExploring(!exploring)}
      />
    </div>
    {!!accountInfo &&
      <div className='configOption DesktopOnly'>
        <label>Change Available Hexes?</label>
        <button onClick={() => navigate('/hex-explorer/hexes')}>Edit Hexes</button>
      </div>
    }
  </>;
}
