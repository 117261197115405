import { TableCheckRow } from 'models/table-check-row';
import './EditTableRows.scss';
import { TableCheck } from 'models/table-check';
import EditTableRow from '../EditTableRow/EditTableRow';
import { useRef, useState } from 'react';
import { capitalizeFirstLetter, parseCSV } from 'utils/strings';
import { cloneArrayExceptId } from 'utils/cloning';
import ToggleGroup from 'components/shared/ToggleGroup';
import { TableCheckProperty } from 'models/table-check-properties';
import ToggleSwitch from 'components/shared/ToggleSwitch';

type Mode = 'rows' | 'text';
export default function EditTableRows({
  tableData,
  rows,
  setRows,
}: {
  tableData: TableCheck,
  rows: TableCheckRow[],
  setRows: (r: TableCheckRow[]) => void
}) {
  const [mode, setMode] = useState<Mode>('rows')
  const [propBehavior, setPropBehavior] = useState('parse')
  const [evenOdds, setEvenOdds] = useState(false)
  const ref = useRef<HTMLTextAreaElement>(null);

  function distributeOdds(rows: TableCheckRow[]): TableCheckRow[] {
    const diceIncrement = Math.floor(tableData.roll / rows.length);
    for (let i = 0; i < rows.length; i++) {
      let newMax = diceIncrement*(i+1);
      if (newMax > tableData.roll) newMax = tableData.roll;
      rows[i].max_result = newMax;
    }
    return rows;
  }
  function rebalanceRows(direction: "up" | "down", startIdx: number, newRows: TableCheckRow[], swap: boolean): TableCheckRow[] {
    if (evenOdds) {
      return distributeOdds(newRows);
    } else if (direction === 'up') {
      for (let i = startIdx+1; i < newRows.length; i++) {
        if (newRows[i].max_result <= newRows[i-1].max_result) {
          if (swap) {
            let isTerminal = false;
            const rowA = {...newRows[i-1]};
            const rowB = {...newRows[i]};
            if (rowA.max_result === rowB.max_result) {
              rowB.max_result--;
              isTerminal = true;
            }
            newRows[i-1] = rowB;
            newRows[i] = rowA;
            if (isTerminal) {
              newRows = rebalanceRows('down', i-1, newRows, false);
              break;
            }
          } else {
            newRows[i].max_result = newRows[i-1].max_result+1;
          }
        }
      }
    } else {
      for (let j = startIdx-1; j >= 0; j--) {
        if (newRows[j].max_result >= newRows[j+1].max_result) {
          if (swap) {
            let isTerminal = false;
            const rowA = {...newRows[j+1]};
            const rowB = {...newRows[j]};
            if (rowA.max_result === rowB.max_result) {
              rowB.max_result++;
              isTerminal = true;
            }
            newRows[j+1] = rowB;
            newRows[j] = rowA;
            if (isTerminal) {
              newRows = rebalanceRows('up', j+1, newRows, false);
              break;
            }
          } else {
            newRows[j].max_result = newRows[j+1].max_result-1;
          }
        }
      }
    }
    return newRows;
  }

  function addRow() {
    const newRows = [...rows.map(row => ({...row}))];
    newRows.push(buildRow({ max_result: tableData.roll, row_name: '' }));
    setRows(rebalanceRows('down', newRows.length - 1, newRows, false));
  }

  function onRowDelete(row: TableCheckRow): void {
    const newRows = [...rows.filter(r => r.table_check_row_id !== row.table_check_row_id)];
    setRows(evenOdds ? distributeOdds(newRows) : newRows);
  }
  function onRowChange(row: TableCheckRow): void {
    let oldVal = row.max_result;
    let idx = 0;
    const newRows = cloneArrayExceptId(rows, row.table_check_row_id!, 'table_check_row_id', ((oldObj, i) => {
      oldVal = oldObj.max_result;
      idx = i;
      return {...row};
    }));
    if (oldVal !== row.max_result) {
      const wentUp = row.max_result > oldVal;
      setRows(rebalanceRows(wentUp ? 'up' : 'down', idx, newRows, true));
    } else {
      setRows(newRows);
    }
  }

  function buildRowText(rowData: TableCheckRow[]): string {
    if (rows.find(r => r.properties && r.properties.length > 0) && propBehavior !== 'preserve') {
      setPropBehavior('preserve');
    }
    return rowData.map(row => `${row.max_result}|${row.row_name}`).join('\n');
  }

  function maxFromRange(range: string): number {
    const rangeParts = range.trim().split(/[–-]/);
    const maxVal = rangeParts[rangeParts.length-1];
    if (isNaN(+maxVal)) return -1;
    return +maxVal;
  }

  function parseProperties(rowId: string, description: string): TableCheckProperty[] {
    const props: TableCheckProperty[] = [];
    const matches = [...description.matchAll(/([0-9]+d[0-9]+[+-]?[0-9]*) ?([a-zA-Z0-9]*)/gi)];
    matches.forEach(match => {
      const [,dice,label] = match;
      props.push({
        table_check_property_id: crypto.randomUUID(),
        key: `number of ${label}`,
        value: dice,
        type: 'dice_roll'
      });
    });
    return props;
  }

  function buildRow({row_name, max_result, properties = [], parseForProps = false}: {row_name: string, max_result: number, properties?: TableCheckProperty[], parseForProps?: boolean}) {
    const newId = crypto.randomUUID();
    const newProps: TableCheckProperty[] = parseForProps ? parseProperties(newId, row_name) : properties;
    return {
      table_check_row_id: newId,
      table_check_id: tableData.table_check_id,
      row_name,
      max_result,
      properties: newProps
    };
  }

  /**
   * This method attempts to parse a pasted table text into formatted rows.
   * We try to be as forgiving as possible, so fair warning, LOTS OF REGEX AHEAD!
   * 
   * For ease of commenting, some definitions:
   *   "range" is a dice range, which may or may not contain a dash, and contains NO SPACES
   *      valid ranges: "1", "100", "1-12", ""
   *      invalid ranges: "1a", "1 - 2",
   *   "separator" is either a space, tab, or | character
   *   "name" is the text describing the row, which will be used for the row_name field
   * 
   * Supported Formats
   * 
   * Default format:
   *   One row per line of text, containing exactly one range, one separator, and one name
   * two-line CSV format:
   *   Two lines
   *   First line is a series of ranges, each separated by a separator
   *   Second line is a series of names in CSV format, where each name is separated by a comma, and any row names containing commas are surrounded by double quotes
   * Multi-line ranges first format:
   *   All ranges come first, separated by a separator and/or a newline
   *   All names comes next, separated by newlines
   *   Count of ranges and names must match
   * No ranges format
   *   No rows contain a range
   *   Odds will be evenly distributed
   */
  function textToRows(text: string | undefined): void {
    if (text) {
      const textRows = text.split('\n').filter(t => t.trim().length > 0);
      let newRows: TableCheckRow[] = [];
      for (let i = 0; i < textRows.length; i++) {
        const numberRangeMatches = textRows[i].match(/\s*[0-9]*[–-]?[0-9]+[ |\t]*/g);
        // each parsed line should have at least one number range
        if (numberRangeMatches && numberRangeMatches.length > 0) {
          // if we are on the first line, and there are at least two number ranges on this line, then this is not a default format case, and we need to handle it uniquely
          if (i === 0 && numberRangeMatches.length > 1) {
            // two-line CSV format
            if (textRows.length === 2) {
              const descriptionsParse = parseCSV(text);
              if (descriptionsParse.length === 2 && descriptionsParse[1].length === numberRangeMatches.length) {

                  for (let j = 0; j < descriptionsParse[1].length; j++) {
                    newRows.push(buildRow({
                      row_name: descriptionsParse[1][j].trim(),
                      max_result: maxFromRange(numberRangeMatches[j]),
                      properties: rows[j]?.properties,
                      parseForProps: propBehavior === 'parse'
                    }))
                  }
                  break;
              }
            }
          }
          // if we are on the first line, and the first line does NOT contain any non-range characters, then this is a Multi-line ranges first format
          if (i === 0 && textRows[i].search(/[^0-9–-\s]/) === -1) {
            const rangeRows = [textRows[i]];
            let j = 1;
            for (; j < textRows.length && textRows[j].search(/[^0-9–-\s]/) === -1; j++) {
              rangeRows.push(textRows[j]);
            }
            const ranges = rangeRows.join(' ').split(/[ |\t]+/);
            if (textRows.length - j === ranges.length){
              for (let k = 0; k < ranges.length; k++) {
                newRows.push(buildRow({
                  row_name: textRows[k+j].trim(),
                  max_result: maxFromRange(ranges[k]),
                  properties: rows[k]?.properties,
                  parseForProps: propBehavior === 'parse'
                }))
              }
              break;
            }
          }
          // If we get here, then none of the other formats above triggered successfully
          // So default format is assumed going forward

          const range = numberRangeMatches[0].trim();
          const maxVal = maxFromRange(range.replace('|', ''));
          const description = textRows[i].replace(range, '').trim();

          newRows.push(buildRow({
            row_name: description,
            max_result: maxVal,
            properties: rows[i]?.properties,
            parseForProps: propBehavior === 'parse'
          }))
        } else {
          // If the first row doesn't have a range, assume No ranges format
          for (let k = 0; k < textRows.length; k++) {
            newRows.push(buildRow({
              row_name: textRows[k].trim(),
              max_result: k,
              properties: rows[k]?.properties,
              parseForProps: propBehavior === 'parse'
            }))
          }
          distributeOdds(newRows);
          break;
        }
      }
      newRows.sort((a,b) => a.max_result - b.max_result);
      if (newRows.length > tableData.roll) {
        newRows = newRows.slice(0,tableData.roll);
      }
      if (newRows[newRows.length-1].max_result > tableData.roll) {
        newRows[newRows.length-1].max_result = tableData.roll
      }
      newRows = rebalanceRows('down', newRows.length-1, newRows, false);
      if (ref.current) {
        ref.current.value = buildRowText(newRows)
      }
      setRows(newRows);
    } else {
      setRows([]);
    }

  }

  function changeMode(newMode: Mode) {
    setMode(newMode);
  }

  let emptyRow: TableCheckRow | undefined;
  if (rows.length === 0 || rows[rows.length-1]?.max_result < tableData.roll) {
    emptyRow = {
      table_check_row_id: crypto.randomUUID(),
      row_name: 'Nothing',
      max_result: tableData.roll
    };
  }

  const modeOptions: Mode[] = ['rows', 'text'];

  return <div className='EditTableRows'>
    <div className='EditTableRows-Options'>
      <label className='EditTableRows-Odds'>
        Even odds? <ToggleSwitch checked={evenOdds} onChange={setEvenOdds} />
      </label>
      <ToggleGroup className='EditTableRows-Mode' options={modeOptions} value={mode} render={capitalizeFirstLetter} onChange={changeMode} />
    </div>
    {mode === 'rows' ? <>
      <ul className='TableForm-rows'>
        {rows.map((row, idx) => <EditTableRow key={row.table_check_row_id} row={row} idx={idx} numberDisabled={evenOdds} tableData={tableData} rows={rows} onRowChange={onRowChange} onRowDelete={onRowDelete} />)}
        {emptyRow ? <>
          <EditTableRow key='EmptyRow' disabled={true} row={emptyRow} idx={rows.length} tableData={tableData} rows={rows} />
        </> : <></>}
        <li className='EditTableRows-AddRow'><input type='button' value='+Add Row' disabled={rows.length === tableData.roll} onClick={addRow} /></li>
      </ul>
    </>
    : <>
      <div>
        <label>
          Properties:
          <ToggleGroup options={['parse', 'preserve', 'none']} value={propBehavior} render={capitalizeFirstLetter} onChange={newVal => setPropBehavior(newVal)} />
        </label>
      </div>
      <textarea rows={Math.min(rows.length+4, 20)} cols={50} defaultValue={buildRowText(rows)} ref={ref} onBlur={() => {textToRows(ref.current?.value)}} />
    </>}
  </div>;
}
