import { fetchPrompt } from 'hooks/ai-hooks';
import './AiPromptModal.scss';
import { useEffect, useState } from 'react';
import FixedModal from 'components/shared/FixedModal';
import { Loader } from '@aws-amplify/ui-react';
import { useAccountInfo } from 'hooks/account-hooks';

export default function AiPromptModal({ prompt, open, onRequestClose }: { prompt: string | null, open: boolean, onRequestClose: () => void }) {
  const [accountInfo] = useAccountInfo();
  const [result, setResult] = useState<string>();
  const restricted = process.env.REACT_APP_RESTRICT_AI === 'true' && !accountInfo;

  useEffect(() => {
    if (prompt === null) {
      setResult(undefined);
    } else {
      setResult(undefined);

      if (restricted) {
        setResult('As you cautiously proceed down the dimly lit corridor, a putrid stench fills the air, assaulting your nostrils. Suddenly, from the dark corners of the chamber, five snarling ghouls emerge, their eyes glowing with a malevolent hunger. Among them, a towering form looms - a ghast, its elongated claws dripping with a vile greenish substance. The fiendish creatures fix their gaze upon you, ready to unleash their relentless assault.')
      } else {
        let isMounted = true;
        let output = '';
        fetchPrompt(prompt, val => {
          if (isMounted) {
            output += val;
            setResult(output)
          }
        });
        return () => {isMounted = false};
      }
    }
  }, [prompt, restricted]);

  return <>
    <FixedModal
      isOpen={open}
      onRequestClose={onRequestClose}
      title='Narration'
      footer={<><button onClick={() => onRequestClose()}>Close</button></>}
    >
      <div className='PromptBody'>
        {restricted ? 
          <><p><b>An account is required to generate live narration.</b> This is a sample of what some narration might look like for a table with a result of <i>"You encounter 5 ghouls plus 1 ghast with an average CR of 6"</i>:</p>{result}</>
          : <>{result === undefined ? <Loader /> : <>{result}</>}</>
        }
      </div>
    </FixedModal>
  </>;
}
