import './CookiePolicy.scss';

export default function CookiePolicy() {
  return <>
    <h1>Cookie Policy for GM's Haversack</h1>
    <p>Effective Date: 12/15/2023</p>

    <p>This Cookie Policy explains how GM's Haversack ("we", "us", or "our") uses cookies and similar technologies to recognize you when you visit our website https://gmhaversack.com. It explains what these technologies are and why we use them, as well as your rights to control our use of them.</p>

    <h2>1. What are cookies?</h2>
    <p>Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners to make their websites work, or to work more efficiently, as well as to provide reporting information.</p>

    <h2>2. Why do we use cookies?</h2>
    <p>We use first-party and third-party cookies for several reasons. Some cookies are required for technical reasons in order for our website to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies enable us to track and target the interests of our users to enhance the experience on our website. Third parties serve cookies through our website for advertising, analytics, and other purposes.</p>

    <h2>3. Types of cookies used</h2>
    <h3>a. Essential Website Cookies:</h3>
    <p>These cookies are strictly necessary to provide you with services available through our website and to use some of its features, such as access to secure areas.</p>

    <h3>b. AWS Cognito Hosted UI Cookies:</h3>
    <p>We use AWS Cognito Hosted UI for user sign up/sign in, which utilizes cookies for authentication processes.</p>

    <h3>c. Session and Preference Cookies:</h3>
    <p>We use cookies to store session information ("__session") and remember user preferences for random table filtering and hex exploration settings.</p>

    <h2>4. How can I control cookies?</h2>
    <p>You have the right to decide whether to accept or reject cookies.</p>
    <p>Most browsers allow you to control cookies through their settings preferences. However, if you limit the ability of websites to set cookies, you may worsen your overall user experience.</p>

    <h2>5. Changes to this Policy</h2>
    <p>We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal, or regulatory reasons.</p>

    <h2>6. Contact Us</h2>
    <p>If you have any questions about our use of cookies or other technologies, please email us at gmhaversack@gmail.com.</p>
  </>;
}
