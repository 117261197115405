import './ExternalTools.scss';

interface ExternalTool {
  name: string,
  href: string,
  description?: string,
  image?: string
}

const tools: ExternalTool[] = [
  {name: 'Tabletop Audio', href: 'https://tabletopaudio.com/', description: ` is a fantastic tool for providing ambient music and atmosphere for your game.`},
  {name: 'SorteKanin', href: 'https://sortekanin.com/', description: ' is another collection of tools, notably with a Pathfinder search/item generation tool.'},
  {name: 'Random Tables for DMs by DMs', href: 'https://www.reddit.com/r/BehindTheTables/', description: ' is a sub-reddit with a very long list of fantastic random dice tables, which was also the source for the default supplied table "Random NPC". This is a great resource for finding tables you like to add in to your Haversack list.'},
  {name: 'Archives of Nethys', href: 'https://www.aonprd.com/GMScreen.aspx', description: ' is a great rules resource for both editions of Pathfinder. This is a specific list to handy rules laid out in their GM Screen.'}
];

export default function ExternalTools() {
  return <>
    <p>These are other tools that I find helpful when running a game that are NOT a part of this site. These are ALL external links that will open in a new tab, provided here for ease of access.</p>
    <p>If you have a TTRPG-related tool you'd like me to add here, you can contact me at <a href='mailto:gmhaversack@gmail.com'>gmhaversack@gmail.com</a> to suggest it.</p>
    <h4>External Tools List</h4>
    {tools.map(tool => <>
      {tool.image && <a href={tool.href}><img alt={tool.name} src={tool.image} /></a>}
      <p><a href={tool.href} target='_blank' rel='noreferrer'>{tool.name}</a>{tool.description}</p>
    </>)}
  </>;
}
