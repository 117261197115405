import { TableCheckProperty, TableCheckPropertyType } from 'models/table-check-properties';
import './EditTableRowProperties.scss';
import Select from 'react-select';
import { useTables } from 'hooks/random-table-hooks';
import { TableCheck } from 'models/table-check';
import { Trash, Edit } from 'react-feather';
import IconButton from 'components/shared/IconButton';
import EditTableModal from 'components/RandomTables/EditTableModal';
import { useModals } from 'hooks/modal-stack'
import { useAccountInfo } from 'hooks/account-hooks';

const typeDisplayMap: Record<TableCheckPropertyType, string> = {
  dice_roll: 'Dice Roll',
  table_check: 'Table Check',
  reroll_over: 'Reroll Over',
  reroll_under: 'Reroll Under',
  result_display: 'Result Display',
  other: 'Other',
}

type TypeOption = {value: TableCheckPropertyType, label: string};
const typeOptions: TypeOption[] = (Object.keys(typeDisplayMap) as TableCheckPropertyType[]).map(type => ({ value: type, label: typeDisplayMap[type]}));
const typeOptionMap: Record<TableCheckPropertyType, TypeOption> = typeOptions.reduce((map, option) => ({...map, [option.value]: option}), {} as Record<TableCheckPropertyType, TypeOption>);

function TableRowProperty({
  prop,
  onPropertyChanged,
  onPropertyDeleted
}: {
  prop: TableCheckProperty,
  onPropertyChanged: (prop: TableCheckProperty) => void,
  onPropertyDeleted: (prop: TableCheckProperty) => void
}) {
  const [accountInfo] = useAccountInfo();
  const { openModal, closeModal } = useModals()
  const { data, isLoading } = useTables({ userId: accountInfo?.userId });

  if (prop.type === 'table_check' && isLoading) return <></>;

  type TableOption = { value: string, label: TableCheck['table_name'] };
  const tableOptionMap: Record<string, TableOption> = {};
  const tableOptions: TableOption[] = data ? data.results.map(table => {
    const newOption = { value: table.table_check_id, label: table.table_name};
    tableOptionMap[table.table_check_id] = newOption;
    return newOption;
  }) : [];
  tableOptions.unshift({ value: 'new', label: 'Add a new table...'});

  function setType(type?: TableCheckPropertyType) {
    if (prop.type !== type)
      onPropertyChanged({...prop, type: type ?? 'other', value: ''});
  }
  function openTableModal(id?: string) {
    openModal(EditTableModal, {tableId: id, onSave: setSubTable, onRequestClose: () => {
      closeModal()
    }});
  }
  function setSubTable(id?: string) {
    if (id === 'new') {
      openTableModal();
    } else {
      onPropertyChanged({...prop, value: `${id}`});
    }
  }
  function setName(name: string) {
    if (prop.key !== name) {
      onPropertyChanged({...prop, key: name});
    }
  }
  function setValue(value: string) {
    if (prop.value !== value)
      onPropertyChanged({...prop, value});
  }
  return <>
    <div>
      <Select options={typeOptions} defaultValue={typeOptionMap[prop.type]} onChange={(opt) => setType(opt?.value)} />
    </div>
    {prop.type === 'result_display' && <>
      <textarea className='DisplayInput' name='value' defaultValue={prop.value} onBlur={(e) => setValue(e.target.value)} />
    </>}
    {prop.type !== 'result_display' && <>
      <div>
        <input type='text' name='key' defaultValue={prop.key} onBlur={(e) => setName(e.target.value)} />
      </div>
      <div className='ValueContainer'>
        {prop.type === 'table_check' ? <>
          <Select
            value={tableOptionMap[prop.value]}
            onChange={(opt) => setSubTable(opt?.value)}
            options={tableOptions}
          />
        </> : <>
          <input type='text' name='value' defaultValue={prop.value} onBlur={(e) => setValue(e.target.value)} />
        </> }
      </div>
    </>}
    <div className='DeleteContainer'>
      {prop.type === 'table_check' && <>
        <IconButton onClick={() => {openTableModal(prop.value)}}>
          <Edit />
        </IconButton>
      </>}
      <IconButton onClick={() => {onPropertyDeleted(prop)}}>
        <Trash />
      </IconButton>
    </div>
  </>;
}

export default function EditTableRowProperties({
  className,
  properties = [],
  onPropertyChanged,
  onPropertyDeleted
}: {
  className?: string
  properties?: TableCheckProperty[]
  onPropertyChanged: (prop: TableCheckProperty) => void
  onPropertyDeleted: (prop: TableCheckProperty) => void
}) {
  function addProperty() {
    onPropertyChanged({
      table_check_property_id: crypto.randomUUID(),
      key: '',
      value: '',
      type: 'dice_roll'
    });
  }

  return <div className={`PropTable ${className}`}>
    <span className='PropHeader'>Type</span>
    <span className='PropHeader'>Label</span>
    <span className='PropHeader'>Value</span>
    <span className='PropHeader'></span>
    {properties.map(prop => <TableRowProperty key={prop.table_check_property_id} prop={prop} onPropertyChanged={onPropertyChanged} onPropertyDeleted={onPropertyDeleted} />)}
    <button onClick={addProperty}>+Add Property</button>
  </div>;
}
