import { RollResults } from 'hooks/random-table-hooks';
import './ShowTableRollResults.scss';
// @ts-ignore
import parser from 'bbcode-to-react';

function getResultText(result: RollResults): string {
  return result.result && result.result.length > 0 ? result.result : 'Nothing';
}

export default function ShowTableRollResults({ result, showResultText = true, showDiceRoll = false, count }: { result?: RollResults | null, showResultText?: boolean, showDiceRoll?: boolean, count?: number }) {
  const displayStr = result ? Object.values(result.properties).find(p => p.type === 'result_display')?.value : undefined;
  return <>
    {result && <>
      <div className='ShowResults'>
        {displayStr ? <>
          {parser.toReact(displayStr)}
        </> : <>
          {showDiceRoll && <div><span className='ResultLabel'>Rolled:</span> {result.roll}</div>}
          {showResultText && <div><span className='ResultLabel'>Result{count ? ` ${count}` : ''}:</span> {getResultText(result)}</div>}
        </>}
        <ul className='ResultList'>
          {Object.values(result.properties).map(propResult => <>
            <li>
              {propResult.type === 'table_check' && propResult.value !== undefined ?
                <>
                  {propResult.key && isNaN(Number.parseInt(propResult.key)) && <span className='ResultLabel'>{propResult.key}:&nbsp; </span>}
                  {propResult.value.length === 1 ? <>
                    {getResultText(propResult.value[0])}
                    {propResult.value.map(val => <><ShowTableRollResults result={val} showResultText={false} /></>)}
                  </> : <>
                    {propResult.value.map((val, idx) => <><ShowTableRollResults result={val} count={idx+1} /></>)}
                  </>}
                </> : 
                <>
                  {propResult.key && displayStr === undefined && <span className='ResultLabel'>{propResult.key}:&nbsp; </span>}
                  {displayStr === undefined && `${propResult.value}`}
                </>}
            </li>
          </>)}
        </ul>
      </div>
    </>}
  </>;
}

