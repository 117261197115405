import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import API from 'api';
import { AiContext } from 'models/ai-context';

function readChunks(reader: any) {
  return {
      async* [Symbol.asyncIterator]() {
          let readResult = await reader.read();
          while (!readResult.done) {
              yield readResult.value;
              readResult = await reader.read();
          }
      },
  };
}

export const fetchPrompt = async (prompt: string, onChunk: (val: string) => void ) => {
  fetch(`${process.env.REACT_APP_BASE_URL}/ai/prompt`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: "POST",
    body: JSON.stringify({prompt}),
    credentials: 'include'

  }).then(async (response) => {
    const reader = response.body?.getReader();
    const decoder = new TextDecoder();
    for await (const chunk of readChunks(reader)) {
      onChunk(decoder.decode(chunk));
    }
  });
};

export const useAiContext = () => {
  return useQuery<AiContext>({
    queryKey: ['ai_context'],
    queryFn: () => {
      return API
        .get(`/ai/context`)
        .then((res) => res.data.result);
    }
  });
}

export const useAiContextRefresh = () => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries({ queryKey: ['ai_context'] });
}

export const useAiContextCreateMutation = () => {
  const refreshQueryList = useAiContextRefresh();
  return useMutation({
    mutationFn: (newAiContext: AiContext) => API.post('/ai/context', newAiContext),
    onSuccess: refreshQueryList
  })
}

export const useAiContextUpdateMutation = () => {
  const refreshQueryList = useAiContextRefresh();
  return useMutation({
    mutationFn: (updatedAiContext: AiContext) => API.put(`/ai/context/${updatedAiContext.ai_context_id}`, updatedAiContext),
    onSuccess: refreshQueryList
  })
}

export const useAiContextDeleteMutation = () => {
  const refreshQueryList = useAiContextRefresh();
  return useMutation({
    mutationFn: (aiContextToDelete: AiContext) => API.delete(`/ai/context/${aiContextToDelete.ai_context_id}`),
    onSuccess: refreshQueryList  
  })
}