import { NavLink } from 'react-router-dom';
import './AppFooter.scss';
import { isPremium, useAccountInfo } from 'hooks/account-hooks';

export default function AppFooter() {
  const [accountInfo] = useAccountInfo();
  const showDonateButton = !isPremium(accountInfo);
  return <>
    <div className='App-footer'>
      {showDonateButton && <p>Like this site? Please consider <NavLink to={`${process.env.REACT_APP_PATREON_LINK}`}>supporting</NavLink>.</p>}
      <div className='Footer-linkList'>
        <NavLink to='/privacy-policy'>Privacy Policy</NavLink>
        <NavLink to='/cookie-policy'>Cookie Policy</NavLink>
        <NavLink to='/license-info'>Open Gaming License</NavLink>
      </div>
    </div>
  </>;
}
