import { Heading, Image } from '@aws-amplify/ui-react';
import './About.scss';
import { NavLink } from 'react-router-dom';
import { isPremium, useAccountInfo } from 'hooks/account-hooks';

export default function About() {
  const [accountInfo] = useAccountInfo();

  const showDonateButton = !isPremium(accountInfo);

  return <>
    <Heading level={3}>About me</Heading>
    <Image src='/profilePic.jpg' alt='Dustin Wilhelmi picture' className='About-profilePic' />
    <p>
      Hello, I'm Dustin, a software engineer by day, and a rookie GM by night. After GMing for a while, there were a handful of things that I found really annoying and time consuming, especially when trying to run a sandbox campaign. After being laid off, I suddenly found myself with the time and availability to do something about it - so GM's Haversack was born! Once I got to building, I received some positive feedback from other GMs I know, that this might be something others could use too. So, here it is - my personal digital toolbox, made available to all of you. I hope you enjoy it!
    </p>
    <Heading level={3}>About GM's Haversack</Heading>
    <Image src='/gms-haversack-logo-small.png' alt='GM Haversack Icon' className='About-siteIcon' />
    <p>
      Currently I offer three tools: Random Tables, Hex Explorer, and NarrateAI. Random Tables is a core tool that can be used to quickly roll against any dice table out there - several that I pre-built and made available to everyone, and each user has the ability to add their own custom tables on top of that. Hex Explorer right now is just a way to one-click explore a hex in any exploration-style game. You can define what hexes you want to use, with what icons, and what random table to roll against when you explore it. Then when you click on that hex, it will roll against the table, show the results, and also say how long it takes the party to fully explore that hex. Finally, NarrateAI is a generative AI tool that will generate narration for you to read out at your game, in case you, like me, struggle to come up with vivid descriptive text. I also hooked narration buttons into other places in the site, to narrate table or hex results.
    </p>
    <p>
      In the future, I hope to add additional tools to the haversack. Possible future ideas include an initiative tracker, or a customizable quick cheat sheet for the rules I always have to look up in the middle of a game. I'm also open to recommendations! My Patreon supporters can weigh in on what they would like to see added next, either by voting on existing ideas or surfacing ideas of their own. I'm pretty responsive and quick, so keep an eye out!
    </p>
    {showDonateButton && <>
      <Heading level={3}>Support</Heading>
      <Image src='/patreon-icon.png' alt='Patreon logo' className='About-patreonLogo' />
      <p>
        If you find some use out of my site, please consider <NavLink to={`${process.env.REACT_APP_PATREON_LINK}`}>donating</NavLink> to support it. Hosting a site like this is not free, and gets significantly less free the more people use it! In order for me to continue offering this up for public use, I need to cover those hosting costs, and the only way for me to do that is through my <NavLink to={`${process.env.REACT_APP_PATREON_LINK}`}>Patreon</NavLink>.
      </p>
    </>}
  </>;
}
