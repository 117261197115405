import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import ComingSoon from 'components/ComingSoon';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
if (process.env.REACT_APP_COMING_SOON === 'true') {
  root.render(
    <ComingSoon />
  );
} else {
  root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
