import { Button, Heading, Image, Loader } from '@aws-amplify/ui-react';
import './Home.scss';
import { signInWithRedirect } from 'aws-amplify/auth';
import { isPremium, useAccountInfo } from 'hooks/account-hooks';
import { NavLink } from 'react-router-dom';

interface HomeItem {
  name: string,
  route: string,
  image?: string,
  desc: string
}
export default function Home({navEnabled = true}: {navEnabled?: boolean}) {
  const [accountInfo, loading] = useAccountInfo({enabled: navEnabled});

  const isLoggedIn = navEnabled && accountInfo !== undefined;
  const showDonateButton = !isPremium(accountInfo);
  const tools: HomeItem[] = [
    {name: 'Random Tables', route: 'random-tables', desc: 'Create dynamic random dice tables with support for nested rolls and custom dice options. Stop having to take the time to make multiple rolls in the middle of the game to figure out what happens next - with a single click, you\'ll get all your table results and get you back to the game.'},
    {name: 'Hex Explorer', route: 'hex-explorer', desc: 'Hex explorations take a lot of time, reduce that to a single click to see what all your players find and how long they took to find it.'},
    {name: 'NarrateAI', route: 'narrate-ai', desc: 'Stuck for words? Let NarrateAI inspire you with rich, AI-generated story elements and ideas'},
  ];

  if (showDonateButton) {
    tools.push({name: 'More to Come!', image: 'patreon-icon.png', route: `${process.env.REACT_APP_PATREON_LINK}`, desc: 'We will continue adding more tools to your tool bag! Want to have input on what we add next? Become a Patreon supporter!'});
  }

  return <>
    <Image alt='GM Haversack Home' src='/gms-haversack-banner.png' />
    {loading ? <>
        <Loader />
      </> :
      <div className='HomeWrapper'>
        {isLoggedIn ? <>
          <Heading level={3}>Welcome Back, {accountInfo.username}!</Heading>
          <p>Where would you like to go first?</p>
        </> : <>
          <Heading level={3} textAlign='center'>Elevate Your TTRPG Experience with Digital Convenience</Heading>
          <p>Welcome to GM's Haversack!</p>
          <p>
            This is a bag of handy tools to enhance and streamline things that GM's often have to do. Let our platform take care of the annoying bits of game mastering, and enable you to focus on interacting with your players and keeping the game moving. GM's Haversack is here to equip you with everything you need to bring your game world to life with ease and flair. Check out what tools we currently offer below, or just <Button className='Home-link' variation='link' onClick={() => signInWithRedirect()}>jump right in</Button>!
          </p>
        </>}
        
        {tools.map((tool) => <>
          <div className={`HomeItem HomeItem--left`}>
            <Image alt={`${tool.name}`} src={tool.image ?? `/${tool.route}-icon.png`} />
            <Heading level={4}>
              {navEnabled ? <>
                <NavLink to={tool.route}>{tool.name}</NavLink>
              </> : <>
              {tool.name}
              </>}
            </Heading>
            <p>{tool.desc}</p>
          </div>
        </>)}

        {!isLoggedIn && navEnabled && 
          <Heading level={3} textAlign='center' marginTop='40px'>Ready to streamline your game? <Button variation='primary' size='large' onClick={() => signInWithRedirect()}>Sign up now!</Button></Heading>
        }
      </div>
    }
  </>;
}
