import { ReactNode } from 'react';
import './IconButton.scss';

export default function IconButton({
  children,
  className,
  disabled,
  ariaLabel = 'Icon Button',
  onClick
}: {
  children: ReactNode,
  className?: string,
  disabled?: boolean,
  ariaLabel?: string,
  onClick?: () => void
}) {
  return <>
    <button disabled={disabled} className={`IconButton ${className}`} onClick={onClick} aria-label={ariaLabel} >
      {children}
    </button>
  </>;
}
