import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import API from 'api';
import { Hex } from 'models/hex';

const fetchHexes = async (): Promise<{status: string, results: Hex[]}> => {
  return API
    .get('/hexes')
    .then((res) => res.data).catch(e => {
      console.log('error', e);
    });
};

export const useHexes = ({userId = 'global'}: {userId?: string}) => {
  return useQuery({
    queryKey: ['hexes', userId],
    queryFn: fetchHexes,
  });
};

export const useHex = ({hexId, defaultData}: {hexId?: string, defaultData: Hex}) => {
  return useQuery<Hex>({
    queryKey: ['hex', hexId],
    queryFn: () => {
      return hexId ? API
        .get(`/hexes/${hexId}`)
        .then((res) => res.data.result) :
      {...defaultData};
    },
  });
}

export const useHexRefresh = () => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries({ queryKey: ['hexes'] });
}

export const useHexCreateMutation = () => {
  const refreshQueryList = useHexRefresh();
  return useMutation({
    mutationFn: (newHexData: Hex) => API.post('/hexes', newHexData),
    onSuccess: refreshQueryList
  })
}

export const useHexUpdateMutation = () => {
  const refreshQueryList = useHexRefresh();
  return useMutation({
    mutationFn: (updatedHexData: Hex) => API.put(`/hexes/${updatedHexData.hex_id}`, updatedHexData),
    onSuccess: refreshQueryList
  })
}

export const useHexDeleteMutation = () => {
  const refreshQueryList = useHexRefresh();
  return useMutation({
    mutationFn: (hexToDelete: Hex) => API.delete(`/hexes/${hexToDelete.hex_id}`),
    onSuccess: refreshQueryList  
  })
}