import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import React from 'react';

export interface UserInfo {
  userId: string,
  username: string,
  groups: string[]
}

export function isPremium(user?: UserInfo): boolean {
  return user?.groups?.includes('premium') ?? false;
}
export function isAdmin(user?: UserInfo): boolean {
  return user?.groups?.includes('admin') ?? false;
}
export async function getAuthUser(): Promise<UserInfo> {
  const session = await fetchAuthSession();
  const user = await getCurrentUser();
  const groups = session.tokens?.idToken?.payload?.['cognito:groups'] as string[];
  return {
    userId: user.userId,
    username: user.username,
    groups
  };
}
export function useAccountInfo({enabled = true}: {enabled?: boolean} = {}): [UserInfo | undefined, boolean, unknown] {
  const [userInfo, setUserInfo] = React.useState<UserInfo>();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<unknown>();

  React.useEffect(() => {
    async function currentAuthenticatedUser() {
      try {
        setLoading(true);
        setUserInfo(await getAuthUser());
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }

    if (enabled) currentAuthenticatedUser();
  }, [enabled]);

  return [userInfo, loading, error];
}