import { ReactNode } from 'react';
import './SlideOutMenu.scss';
import IconButton from '../IconButton';
import { X } from 'react-feather';
import { useClickOutside } from 'hooks/click-outside';

export default function SlideOutMenu({
  children,
  width = 180,
  collapsed,
  setCollapsed,
  side = 'left',
  className = ''
}: {
  children: ReactNode,
  width?: number | string,
  collapsed: boolean,
  setCollapsed: (val: boolean) => void,
  side?: 'left' | 'right',
  className?: string
}) {
  const ref = useClickOutside({onClickOutside: () => setCollapsed(true), enabled: !collapsed});
  const root = document.querySelector(':root');
  // @ts-ignore
  root.style.setProperty('--bar-width', typeof width === 'string' ? width : `${collapsed ? 180 : width}px`);
  return <div ref={ref} className={`SideBar ${className} ${collapsed ? 'SideBar--hidden' : ''} ${side === 'left' ? 'SideBar--left' : 'SideBar--right'}`}>
    <IconButton className='SideBar-close' onClick={() => setCollapsed(true)}><X color='white' /></IconButton>
    {children}
  </div>;
}
