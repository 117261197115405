import { useAiContext, useAiContextCreateMutation, useAiContextUpdateMutation } from 'hooks/ai-hooks';
import './NarrateSettings.scss';
import { useEffect, useRef, useState } from 'react';
import { Heading } from '@aws-amplify/ui-react';
import Select from 'react-select';
import { AiLength } from 'models/ai-context';

const lengthDisplayMap: Record<AiLength, string> = {
  'short': 'Shorter (2-3 sentences)',
  'average': 'Average (4-5 sentences)',
  'long': 'Longer (6-8 sentences)',
}
const narrationLengthOptions: AiLength[] = ['short', 'average', 'long'];
type LengthOption = {value: AiLength, label: string};
const lengthOptions: LengthOption[] = narrationLengthOptions.map(terrain => ({ value: terrain, label: lengthDisplayMap[terrain]}));
const lengthOptionMap: Record<AiLength, LengthOption> = lengthOptions.reduce((map, option) => ({...map, [option.value]: option}), {} as Record<string, LengthOption>);

export default function NarrateSettings({onSave}: {onSave: () => void}) {
  const ref = useRef<HTMLTextAreaElement>(null);
  const [length, setLength] = useState<AiLength>();
  const { data } = useAiContext();
  const updateMutation = useAiContextUpdateMutation();
  const createMutation = useAiContextCreateMutation();

  useEffect(() => {
    setLength(data?.length);
  }, [data]);

  function saveContext() {
    if (data?.ai_context_id === undefined) {
      createMutation.mutate({context: ref.current?.value ?? '', length});
    } else {
      updateMutation.mutate({...data, context: ref.current?.value ?? '', length});
    }
    onSave();
  }
  return <>
    <Heading color='white' level={3}>Narration Settings</Heading>
    <div className='configOption'>
      <label>
        How long would you like your average narration to be?
      </label>
      <Select
        value={lengthOptionMap[length ?? 'short']}
        onChange={(opt) => setLength(opt?.value)}
        options={lengthOptions}
        className='NarrateSettings-dropdown'
      />
    </div>
    <div className='configOption'>
      <label>The narration is powered by Generative AI, and has general knowledge of fantasy RPG settings. Any information you want to provide the AI engine here will be given as background information, to help flavor the narration that the AI tool provides. It can be as simple as "I am playing a Pathfinder 1e game", or as complicated as a description of your homebrew world, custom races, number of players, or any other information you want!</label>
      <textarea ref={ref} rows={10} defaultValue={data?.context} />
      <div>
        <button onClick={saveContext}>Save narration settings</button>
      </div>
    </div>
  </>;
}
